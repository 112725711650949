import styles from './MapResults.module.scss';
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import MapResultItem from "../MapResultItem/MapResultItem";
import { useTranslation } from "react-i18next";
import { useEffect, useRef, useState } from "react";
import Icon from "../Icon";
import { TSection } from "../../model/type/api/TSection";
import ParkingSessionItem from "../ParkingSessionItem/ParkingSessionItem";
import useParkingSession from "../../model/useParkingSession";
import useFavoriteZone from "../../model/useFavoriteZone";

type PropsItems = {
	onSelect?: (section: TSection) => void;
} & PropsItemsShared;

type PropsItemsShared = {
	wrapperClassName?: string;
	snapIndex?: number;
	onChangeScroll?: (y: number) => void;
	zones?: TSection[];
	filteredResults?: TSection[];
	hideResults: boolean;
	currentSectionCode?: string;
};

type PropsItem = {
	type: string;
	items?: TSection[];
	onSelect?: (section: TSection) => void;
} & PropsItemsShared;

const MapResultItems: React.FC<PropsItem> = ({ onSelect, items = [], type, ...props }) => {
	const ref = useRef<HTMLDivElement>(null);
	const { t } = useTranslation();

	let _styles = {};
	
	const heightOfList = () => {
		const fullHeight = window.innerHeight;

		return {
			0: `calc(${fullHeight}px - 225px)`,
			1: `calc(${fullHeight}px)`,
		}[props.snapIndex];
	};

	if (props.snapIndex !== undefined) {
		_styles = { height: heightOfList(), overflowY: props.snapIndex === 1 ? "hidden" : "scroll" };
	}

	const [scrollTop, setScrollTop] = useState(0);

	useEffect(() => {
		const onScroll = (e: any) => {
			setScrollTop(e.target.scrollTop);
			props.onChangeScroll && props.onChangeScroll(e.target.scrollTop);
		};
		ref.current?.addEventListener("scroll", onScroll);

		return () => {
			ref.current?.removeEventListener("scroll", onScroll);
		};
	}, [scrollTop]);

	const sortedItems = props.hideResults
		? []
		: items.sort((a, b) => {
			if ((a.featureType === "parkingZone" ? a.sectionID : a.parkMachineID) === props.currentSectionCode) return -1;
			if ((b.sectionCode === "parkingZone" ? b.sectionID : b.parkMachineID) === props.currentSectionCode) return 1;
			return 0;
		});

	const { parkingSession } = useParkingSession();
	const { zones, deleteRecord: deleteZoneRecord } = useFavoriteZone();

	return (
		<div className={styles.items} style={_styles} ref={ref}>
			{parkingSession.map((session) => (
				<ParkingSessionItem
					key={session.guid}
					from={session.from}
					to={session.to}
					ticketGUID={session.guid}
					carPlate={session.ticket.licensePlate}
				/>
			))}
			{zones.map((zone) => {
				if (zone?.json === undefined || !zone?.json?.featureType) {
					deleteZoneRecord(zone.id);
				}

				const section = JSON.parse(zone.json);


				return (
				<MapResultItem
					key={section.featureType === "parkingZone" ? section.sectionID : section.parkMachineID}
					section={section}
					sectionId={section.sectionID}
					placeName={section.featureType === "parkingZone" ? (section.streetName ?? section.sectionCode) : "Parkovací automat: " + (section.streetName ?? section.parkMachineCode)}
					parkingInfo={`${section.streetName ? (section.featureType === "parkingZone" ? section.sectionCode : section.parkMachineCode) + " - " : ""} max ${section.tariffInfo.maxHour} h`}
					isCurrent={props.currentSectionCode === (section.featureType === "parkingZone" ? section.sectionID : section.parkMachineID)}
					featureType={section.featureType}
					onClick={() => {
						onSelect && onSelect(section);
					}}
				/>
			)})}
			{sortedItems.map((section) => (
				<MapResultItem
					key={section.featureType === "parkingZone" ? section.sectionID : section.parkMachineID}
					section={section}
					sectionId={section.sectionID}
					placeName={section.featureType === "parkingZone" ? (section.streetName ?? section.sectionCode) : "Parkovací automat: " + (section.streetName ?? section.parkMachineCode)}
					parkingInfo={`${section.streetName ? (section.featureType === "parkingZone" ? section.sectionCode : section.parkMachineCode) + " - " : ""} max ${section.tariffInfo.maxHour} h`}
					isCurrent={props.currentSectionCode === (section.featureType === "parkingZone" ? section.sectionID : section.parkMachineID)}
					featureType={section.featureType}
					onClick={() => {
						onSelect && onSelect(section);
					}}
					favorites={false}
				/>
			))}
			{sortedItems.length === 0 && zones.length === 0 && <div>{t("home.MapResultsComponent.noResults")}</div>}
		</div>
	);
};

const MapResults: React.FC<PropsItems> = ({ onSelect = null, ...props }) => {
	const { t } = useTranslation();
	const isLogged = false;

	return (
		<div className={styles.mapResultsWrapper}>
		<div className={`${styles.mapResultsContainer} ${props.wrapperClassName}`}>
			{isLogged && (
				<Tabs
					selectedTabClassName={styles.selected}
					onChange={() => props.onChangeScroll && props.onChangeScroll(0)}
				>
					<TabList className={styles.tabList}>
						<Tab className={styles.tabItem}>
							<Icon.Location className={styles.tabIcon} size={14} />
							{t("home.MapResultsComponent.tab.nearest")}
						</Tab>
						<Tab className={styles.tabItem}>
							<Icon.Hearth className={styles.tabIcon} size={14} />
							{t("home.MapResultsComponent.tab.favourite")}
						</Tab>
						<Tab className={styles.tabItem}>
							<Icon.History className={styles.tabIcon} size={14} />
							{t("home.MapResultsComponent.tab.history")}
						</Tab>
					</TabList>

					<TabPanel>
						<MapResultItems type={"nearest"} {...props} />
					</TabPanel>
					<TabPanel>
						<MapResultItems type={"favourite"} {...props} />
					</TabPanel>
					<TabPanel>
						<MapResultItems type={"history"} {...props} />
					</TabPanel>
				</Tabs>
			)}

			{!isLogged && (
				<MapResultItems items={props.filteredResults} type={"filtered"} onSelect={onSelect} {...props} />
			)}
		</div>
		</div>
	);
};

export default MapResults;
