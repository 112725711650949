import React, { createContext, useContext, useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";

const PreviousLocationContext = createContext(null);

export const PreviousLocationProvider = ({ children }) => {
    const location = useLocation();
    const prevLocation = useRef(location.pathname);

    useEffect(() => {
        prevLocation.current = location.pathname;
    }, [location]);

    useEffect(() => {
        const handleBeforeUnload = (event) => {
            sessionStorage.removeItem("canUseReloadNavigation");
        };

        window.addEventListener("beforeunload", handleBeforeUnload);

        return () => {
            window.removeEventListener("beforeunload", handleBeforeUnload);
        };
    }, []);

    return (
        <PreviousLocationContext.Provider value={prevLocation.current}>
            {children}
        </PreviousLocationContext.Provider>
    );
};

export const usePreviousLocation = () => {
    return useContext(PreviousLocationContext);
};
