import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import styles from './ParkingSession.module.scss';
import Icon from "../components/Icon";
import {Link, useNavigate, useParams} from "react-router-dom";
import {downloadReceipt, getTicket} from "../model/helpers/Session";
import dayjs from "dayjs";
import {calculateParkingTimes} from "../model/helpers/DateHelper";
import ProgressBar from "../components/ProgressBar/ProgressBar";
import {fetchData} from "../services/api";
import {orderTicket} from "../model/helpers/Payment";
import {Form, Formik} from "formik";

const ParkingSession = () => {
    const {t } = useTranslation();
    const navigate = useNavigate();

    const [shouldRender, setShouldRender] = useState(false);
    const [paymentMethod, setPaymentMethod] = useState(null);
    const [ticketData, setTicketData] = useState(null);
    const [section, setSection] = useState(null);

    let { ticketGUID } = useParams();

    useEffect(() => {
        getTicket(ticketGUID, (data) => {
            data.parkingFrom = dayjs(data.parkingFrom).toDate()
            data.parkingTo = dayjs(data.parkingTo).toDate()

            setTicketData(data);

            if (data.parkMachineCode) {
                setSection(t("home.MapSearchComponent.zones.parkingMachine"))
            }

            fetchData("Parking/sections/1", "GET", "url", {
                "sectionCode": data.sectionCode
            }).then((sectionsData) => {
                if (!sectionsData[0]) {
                    navigate(`/`);
                }
                setSection(sectionsData[0]);
            });

            const { remaining } = calculateParkingTimes(data.parkingFrom, data.parkingTo);

            if (remaining > 0) {
                navigate(`/paid/${ticketGUID}`);
            } else {
                setShouldRender(true);
            }
        })
    }, [ticketGUID, navigate]);

    const handleDownloadReceipt = () => {
        downloadReceipt(ticketGUID);
    };

    const handleOrderTicket = (data) => {
        data.licencePlate = ticketData.licensePlate;

        let email = 'test@appsdevteam.com';
        //orderTicket(ticketData.licensePlate, email, ticketData.acceptedMinutes, paymentMethod, section);
    }

    if (!shouldRender) {
        return null;
    }

    return (
        <Formik initialValues={{}} onSubmit={handleOrderTicket}>
            <div className={styles.containerWrapper}>
                <div className={styles.container}>
                    <Link to={'/'} className={styles.back}>
                        <Icon.CaretLeft color={'danger'} size={16}/>
                        &nbsp; {t("parkingSession.back")}
                    </Link>

                    <h1 className={styles.header}>{t("parkingSession.title")}</h1>

                    <Form>
                        <div className={styles.item}>
                            <div className={`${styles.info}`}>
                                <ProgressBar orderDate={ticketData?.parkingFrom} endDate={ticketData?.parkingTo} />
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-md-6">
                                <div className={styles.item}>
                                    <div className={styles.info}>
                                        <div className={styles.icon}>
                                            <Icon.ParkingSign color={"#1D4F8B"} size={21}/>
                                        </div>
                                        <div className={styles.textMuted}>
                                            {section?.sectionCategoryName}
                                        </div>
                                        <div className={`${styles.itemEnd} ${styles.title} align-items-end`}>
                                            <div className={styles.itemTitle}>
                                                {ticketData?.sectionCode ?? ticketData.parkMachineCode}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className={styles.item}>
                                    <div className={styles.info}>
                                        <div className={styles.icon}>
                                            <Icon.Car color={"#1D4F8B"} size={21}/>
                                        </div>
                                        <div className={styles.textMuted}>
                                            {t('parkingSession.car')}
                                        </div>
                                        <div className={`${styles.itemEnd} ${styles.title} align-items-end`}>
                                            <div className={styles.itemTitle}>
                                                {ticketData?.licensePlate}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-md-6">
                                <div className={styles.item}>
                                    <div className={styles.info}>
                                        <div className={styles.icon}>
                                            <Icon.PaymentCard color={"#1D4F8B"} size={21}/>
                                        </div>
                                        <div className={styles.textMuted}>
                                            {t('parkingSession.paid.title')}
                                        </div>
                                        <div className={`${styles.itemEnd} ${styles.title} align-items-end`}>
                                            <div className={styles.itemTitle}>
                                                {t('parkingSession.paid.byCard')}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className={styles.item}>
                                    <div className={styles.info}>
                                        <div className={styles.icon}>
                                            <Icon.Pricing color={"#1D4F8B"} size={21}/>
                                        </div>
                                        <div className={styles.textMuted}>
                                            {t('parkingSession.price')}
                                        </div>
                                        <div className={`${styles.itemEnd} ${styles.title} align-items-end`}>
                                            <div className={styles.itemTitle}>
                                                {ticketData?.priceTotal}&nbsp;{t('global.currency.czk')}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className={styles.formActions}>
                            <button type="submit"
                                    className={`${styles.actionBtn} ${styles.defaultBtn} ${styles.text500}`}
                                    onClick={() => handleDownloadReceipt()}
                            >
                                <Icon.Document color={'black'} size={16}/>&nbsp;&nbsp;{t('paid.invoice')}
                            </button>

                            <button type="submit" onClick={() => setPaymentMethod('0')} className={`${styles.actionBtn} ${styles.mainBtn}`}>
                                <Icon.Refresh color={'#FFFFFF'} size={16}/>&nbsp;&nbsp;{t('parkingSession.buyAgain')}
                            </button>
                        </div>
                    </Form>
                </div>
            </div>
        </Formik>
    );
}

export default ParkingSession;