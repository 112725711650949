export const DBConfig = {
	name: "PrahaParking",
	version: 18,
	objectStoresMeta: [
		{
			store: "vehicles",
			storeConfig: { keyPath: "id", autoIncrement: true },
			storeSchema: [
				{ name: "name", keypath: "name", options: { unique: false } },
				{ name: "spz", keypath: "spz", options: { unique: true } },
			],
		},
		{
			store: "cards",
			storeConfig: { keyPath: "id", autoIncrement: true },
			storeSchema: [
				{ name: "name", keypath: "name", options: { unique: false } },
				{ name: "token", keypath: "token", options: { unique: false } },
				{ name: "lastNumbers", keypath: "lastNumbers", options: { unique: false } },
				{ name: "cardMaskedCLN", keypath: "cardMaskedCLN", options: { unique: true } },
				{ name: "cardExpiration", keypath: "cardExpiration", options: { unique: false } },
			],
		},
		{
			store: "tokenPaymentSession",
			storeConfig: { keyPath: "id", autoIncrement: true },
			storeSchema: [
				{ name: "uuid", keypath: "uuid", options: { unique: true } },
				{ name: "ticketGUID", keypath: "ticketGUID", options: { unique: false } },
				{ name: "paymentGWReference", keypath: "paymentGWReference", options: { unique: false } }
			],
		},
		{
			store: "parkingSession",
			storeConfig: { keyPath: "id", autoIncrement: true },
			storeSchema: [
				{ name: "guid", keypath: "guid", options: { unique: true } },
				{ name: "from", keypath: "from", options: { unique: false } },
				{ name: "to", keypath: "to", options: { unique: false } },
				{ name: "ticket", keypath: "ticket", options: { unique: false } }
			],
		},
		{
			store: "emails",
			storeConfig: { keyPath: "id", autoIncrement: true },
			storeSchema: [
				{ name: "email", keypath: "email", options: { unique: true } },
			],
		},
		{
			store: "buyFormValues",
			storeConfig: { keyPath: "id", autoIncrement: true },
			storeSchema: [
				{ name: "dateTime", keypath: "dateTime", options: { unique: false } },
				{ name: "values", keypath: "values", options: { unique: false } },
				{ name: "sectionCode", keypath: "sectionCode", options: { unique: false } },
				{ name: "featureType", keypath: "featureType", options: { unique: false } },
			],
		},
		{
			store: "settings",
			storeConfig: { keyPath: "key", autoIncrement: false },
			storeSchema: [
				{ name: "key", keypath: "key", options: { unique: true } },
				{ name: "value", keypath: "value", options: { unique: false } },
			],
		},
		{
			store: "favoriteZones",
			storeConfig: { keyPath: "id", autoIncrement: true },
			storeSchema: [
				{ name: "id", keypath: "id", options: { unique: true } },
				{ name: "json", keypath: "json", options: { unique: false } },
			],
		},
	],
};