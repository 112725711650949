import styles from './Icon.module.scss';
import React from "react";

type Props = {
	size?: number,
	className?: string,
	color?: string,
}

const _icon = (children) => ({size, className, color}: Props) => {
	let colorClass = '';
	if (color === 'active') {
		colorClass = styles.activeColor;
		color = undefined;
	}

    if (color === 'danger') {
        colorClass = styles.dangerColor;
        color = undefined;
    }

    if (color === 'black') {
        colorClass = styles.blackColor;
        color = undefined;
    }

    if (color === 'white') {
        colorClass = styles.whiteColor;
        color = undefined;
    }

	return ( // @ts-ignore
		<i className={`${styles.icon} ${size ? styles.customSize : ''} ${color ? styles.customColor : ''} ${className} ${colorClass}`} style={{
			...(size ? {'--iconSize': size+'px'} : {}),
			...(color ? {'--iconColor': color} : {}),
		}}>{children}</i>
	);
}

const Icon = {
	Email: (
		_icon(
			<svg width="22" height="23" viewBox="0 0 22 23" fill="none" xmlns="http://www.w3.org/2000/svg">
				<path
					d="M9.39453 1.65234C16.0117 0.363281 21.7695 5.39062 21.7695 11.793V12.6953C21.8125 14.7148 20.4805 16.6484 18.5039 17.1211C16.5703 17.5938 14.8086 16.8203 13.7773 15.4883C12.9609 16.1328 11.9297 16.5625 10.8125 16.5625C7.89062 16.5625 5.61328 14.0273 6.04297 11.0625C6.25781 9.30078 7.54688 7.79688 9.17969 7.19531C10.5547 6.72266 11.8438 6.89453 12.875 7.41016C12.9609 7.15234 13.2188 6.9375 13.5625 6.9375H14.9375C15.2812 6.9375 15.625 7.23828 15.625 7.625V12.7812C15.625 13.7266 16.3555 14.5 17.3438 14.5C18.2891 14.5 19.0625 13.7266 19.0625 12.7812V12.0938C19.0625 8.14062 16.1406 4.57422 12.2305 4.1875C7.71875 3.75781 3.9375 7.32422 3.9375 11.75C3.9375 15.5312 6.6875 18.668 10.3398 19.2266C10.9844 19.3125 11.5 19.9141 11.5 20.5586V20.6016C11.5 21.418 10.7266 22.0625 9.91016 21.9336C4.32422 21.1602 0.199219 15.7461 1.35938 9.6875C2.17578 5.69141 5.39844 2.46875 9.39453 1.65234ZM10.7695 13.8555C11.9297 13.8555 12.832 12.9102 12.832 11.793C12.832 10.6328 11.8867 9.73047 10.7695 9.73047C9.60938 9.73047 8.70703 10.6328 8.70703 11.793C8.70703 12.9102 9.60938 13.8555 10.7695 13.8555Z"
					/>
			</svg>
		)
	),
	CaretLeft: (
		_icon(
			<svg viewBox="0 0 7 11" fill="none" xmlns="http://www.w3.org/2000/svg">
				<path
					d="M5.5 10.75C5.28906 10.75 5.10156 10.6797 4.96094 10.5391L0.460938 6.03906C0.15625 5.75781 0.15625 5.26562 0.460938 4.98438L4.96094 0.484375C5.24219 0.179688 5.73438 0.179688 6.01562 0.484375C6.32031 0.765625 6.32031 1.25781 6.01562 1.53906L2.05469 5.5L6.01562 9.48438C6.32031 9.76562 6.32031 10.2578 6.01562 10.5391C5.875 10.6797 5.6875 10.75 5.5 10.75Z"/>
			</svg>
		)
	),
	CaretRight: (
		_icon(
			<svg viewBox="0 0 10 16" fill="none" xmlns="http://www.w3.org/2000/svg">
				<path
					d="M8.6875 7.3125C9.09375 7.6875 9.09375 8.34375 8.6875 8.71875L2.6875 14.7188C2.3125 15.125 1.65625 15.125 1.28125 14.7188C0.875 14.3438 0.875 13.6875 1.28125 13.3125L6.5625 8L1.28125 2.71875C0.875 2.34375 0.875 1.6875 1.28125 1.3125C1.65625 0.90625 2.3125 0.90625 2.6875 1.3125L8.6875 7.3125Z"/>
			</svg>
		)
	),
	Location: (
		_icon(
			<svg viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
				<path
					d="M1.3269 5.75C0.916748 5.75 0.561279 5.47656 0.479248 5.06641C0.397217 4.65625 0.615967 4.24609 0.998779 4.08203L10.6238 0.144531C10.9519 0.0078125 11.3347 0.0898438 11.5808 0.335938C11.8269 0.582031 11.9089 0.964844 11.7722 1.29297L7.83472 10.918C7.67065 11.3008 7.2605 11.5195 6.85034 11.4375C6.44019 11.3555 6.1394 11 6.1394 10.5625V5.75H1.3269ZM7.47925 4.4375V8.34766L10.1589 1.75781L3.56909 4.4375H7.47925Z"/>
            </svg>
        )
    ),
    Hearth: (
        _icon(
			<svg viewBox="0 0 14 13" fill="none" xmlns="http://www.w3.org/2000/svg">
				<path
					d="M0 4.97266V4.83594C0 2.92188 1.36719 1.28125 3.25391 0.980469C4.48438 0.761719 5.76953 1.17188 6.67188 2.07422L7 2.375L7.30078 2.07422C8.20312 1.17188 9.48828 0.761719 10.7188 0.980469C12.6055 1.28125 14 2.92188 14 4.83594V4.97266C14 6.12109 13.5078 7.21484 12.6875 7.98047L7.73828 12.6016C7.54688 12.793 7.27344 12.875 7 12.875C6.69922 12.875 6.42578 12.793 6.23438 12.6016L1.28516 7.98047C0.464844 7.21484 0 6.12109 0 4.97266Z"/>
			</svg>
		)
	),
	History: (
		_icon(
			<svg viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
				<path
					d="M7.8335 0.75C11.689 0.75 14.8335 3.89453 14.8335 7.75C14.8335 11.6328 11.689 14.75 7.8335 14.75C6.38428 14.75 5.07178 14.3398 3.95068 13.6016C3.6499 13.4102 3.56787 13 3.75928 12.6992C3.97803 12.3984 4.38818 12.3164 4.68896 12.5078C5.59131 13.1094 6.65771 13.4375 7.8335 13.4375C10.9507 13.4375 13.521 10.8945 13.521 7.75C13.521 4.63281 10.9507 2.0625 7.8335 2.0625C5.81006 2.0625 4.03271 3.12891 3.021 4.6875H4.55225C4.90771 4.6875 5.2085 4.98828 5.2085 5.34375C5.2085 5.72656 4.90771 6 4.55225 6H1.48975C1.10693 6 0.833496 5.72656 0.833496 5.34375V2.28125C0.833496 1.92578 1.10693 1.625 1.48975 1.625C1.84521 1.625 2.146 1.92578 2.146 2.28125V3.67578C3.40381 1.92578 5.48193 0.75 7.8335 0.75ZM7.8335 4.25C8.18896 4.25 8.48975 4.55078 8.48975 4.90625V7.50391L10.2397 9.25391C10.5132 9.52734 10.5132 9.9375 10.2397 10.1836C9.99365 10.457 9.5835 10.457 9.3374 10.1836L7.36865 8.21484C7.23193 8.10547 7.17725 7.94141 7.17725 7.75V4.90625C7.17725 4.55078 7.45068 4.25 7.8335 4.25Z"/>
			</svg>
		)
	),
	Account: (
		_icon(
			<svg viewBox="0 0 12 13" fill="none" xmlns="http://www.w3.org/2000/svg">
				<path
					d="M6 6.5C4.33594 6.5 3 5.16406 3 3.5C3 1.85938 4.33594 0.5 6 0.5C7.64062 0.5 9 1.85938 9 3.5C9 5.16406 7.64062 6.5 6 6.5ZM7.17188 7.625C9.42188 7.625 11.25 9.45312 11.25 11.7031C11.25 12.1484 10.875 12.5 10.4297 12.5H1.54688C1.10156 12.5 0.75 12.1484 0.75 11.7031C0.75 9.45312 2.55469 7.625 4.80469 7.625H7.17188Z"/>
			</svg>
		)
	),
	Remove: (
		_icon(
			<svg viewBox="0 0 12 11" fill="none" xmlns="http://www.w3.org/2000/svg">
				<path
					d="M10.6875 9.3125C11.0938 9.6875 11.0938 10.3438 10.6875 10.7188C10.5 10.9062 10.25 11 10 11C9.71875 11 9.46875 10.9062 9.28125 10.7188L6 7.4375L2.6875 10.7188C2.5 10.9062 2.25 11 2 11C1.71875 11 1.46875 10.9062 1.28125 10.7188C0.875 10.3438 0.875 9.6875 1.28125 9.3125L4.5625 6L1.28125 2.71875C0.875 2.34375 0.875 1.6875 1.28125 1.3125C1.65625 0.90625 2.3125 0.90625 2.6875 1.3125L6 4.59375L9.28125 1.3125C9.65625 0.90625 10.3125 0.90625 10.6875 1.3125C11.0938 1.6875 11.0938 2.34375 10.6875 2.71875L7.40625 6.03125L10.6875 9.3125Z"/>
			</svg>
		)
	),
	RemoveTiny: (
		_icon(
			<svg viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
				<path
					d="M15.2656 15.3125C14.9844 15.5938 14.4688 15.5938 14.1875 15.3125L8 9.07812L1.76562 15.3125C1.48438 15.5938 0.96875 15.5938 0.6875 15.3125C0.40625 15.0312 0.40625 14.5156 0.6875 14.2344L6.92188 8L0.6875 1.8125C0.40625 1.53125 0.40625 1.01562 0.6875 0.734375C0.96875 0.453125 1.48438 0.453125 1.76562 0.734375L8 6.96875L14.1875 0.734375C14.4688 0.453125 14.9844 0.453125 15.2656 0.734375C15.5469 1.01562 15.5469 1.53125 15.2656 1.8125L9.03125 8L15.2656 14.2344C15.5469 14.5156 15.5469 15.0312 15.2656 15.3125Z"/>
			</svg>
		)
	),
	Search: (
		_icon(
			<svg viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
				<path
					d="M16.625 14.875C17.0938 15.375 17.0938 16.1562 16.625 16.6562C16.125 17.125 15.3438 17.125 14.8438 16.6562L11.125 12.9062C9.84375 13.75 8.28125 14.1875 6.59375 13.9688C3.71875 13.5625 1.40625 11.2188 1.03125 8.375C0.5 4.125 4.09375 0.53125 8.34375 1.0625C11.1875 1.4375 13.5312 3.75 13.9375 6.625C14.1562 8.3125 13.7188 9.875 12.875 11.125L16.625 14.875ZM3.46875 7.5C3.46875 9.71875 5.25 11.5 7.46875 11.5C9.65625 11.5 11.4688 9.71875 11.4688 7.5C11.4688 5.3125 9.65625 3.5 7.46875 3.5C5.25 3.5 3.46875 5.3125 3.46875 7.5Z"/>
			</svg>
		)
	),
	PaymentCard: (
		_icon(
			<svg viewBox="0 0 19 14" fill="none" xmlns="http://www.w3.org/2000/svg">
				<path
					d="M16.5 0C17.5938 0 18.5 0.90625 18.5 2V3H0.5V2C0.5 0.90625 1.375 0 2.5 0H16.5ZM18.5 12C18.5 13.125 17.5938 14 16.5 14H2.5C1.375 14 0.5 13.125 0.5 12V6H18.5V12ZM4 10C3.71875 10 3.5 10.25 3.5 10.5C3.5 10.7812 3.71875 11 4 11H6C6.25 11 6.5 10.7812 6.5 10.5C6.5 10.25 6.25 10 6 10H4ZM8 11H12C12.25 11 12.5 10.7812 12.5 10.5C12.5 10.25 12.25 10 12 10H8C7.71875 10 7.5 10.25 7.5 10.5C7.5 10.7812 7.71875 11 8 11Z"/>
			</svg>
		)
	),
	ApplePay: (
		_icon(
			<svg viewBox="0 0 14 17" fill="none" xmlns="http://www.w3.org/2000/svg">
				<path
					d="M9.61035 2.96387C9.0542 3.63623 8.12451 4.13428 7.38574 4.13428C7.29443 4.13428 7.21143 4.11768 7.16162 4.10938C7.15332 4.06787 7.12842 3.94336 7.12842 3.81055C7.12842 2.96387 7.56006 2.11719 8.0332 1.58594C8.62256 0.888672 9.61865 0.374023 10.4487 0.34082C10.4653 0.432129 10.4736 0.54834 10.4736 0.664551C10.4736 1.51123 10.1167 2.34961 9.61035 2.96387ZM10.1914 4.30859C10.6562 4.30859 12.3413 4.3584 13.4453 5.94385C13.3457 6.01855 11.6772 6.96484 11.6772 9.07324C11.6772 11.5054 13.8022 12.377 13.8687 12.3936C13.8604 12.4517 13.5283 13.5723 12.748 14.7261C12.0425 15.7305 11.3037 16.7515 10.1914 16.7515C9.0708 16.7515 8.78027 16.0957 7.50195 16.0957C6.24023 16.0957 5.79199 16.7681 4.7793 16.7681C3.75 16.7681 3.03613 15.8301 2.22266 14.6846C1.26807 13.3315 0.504395 11.2314 0.504395 9.23926C0.504395 6.05176 2.57959 4.3584 4.62988 4.3584C5.70898 4.3584 6.60547 5.06396 7.29443 5.06396C7.94189 5.06396 8.95459 4.30859 10.1914 4.30859Z"/>
			</svg>
		)
	),
	Trash: (
		_icon(
			<svg width="14" height="15" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg">
				<path
					d="M4.56641 1.24219C4.70312 0.941406 5.00391 0.75 5.33203 0.75H8.64062C8.96875 0.75 9.26953 0.941406 9.40625 1.24219L9.625 1.625H12.25C12.7148 1.625 13.125 2.03516 13.125 2.5C13.125 2.99219 12.7148 3.375 12.25 3.375H1.75C1.25781 3.375 0.875 2.99219 0.875 2.5C0.875 2.03516 1.25781 1.625 1.75 1.625H4.375L4.56641 1.24219ZM1.72266 4.25H12.25V13C12.25 13.9844 11.457 14.75 10.5 14.75H3.47266C2.51562 14.75 1.72266 13.9844 1.72266 13V4.25ZM3.91016 6.4375V12.5625C3.91016 12.8086 4.12891 13 4.34766 13C4.59375 13 4.78516 12.8086 4.78516 12.5625V6.4375C4.78516 6.21875 4.59375 6 4.34766 6C4.12891 6 3.91016 6.21875 3.91016 6.4375ZM6.53516 6.4375V12.5625C6.53516 12.8086 6.75391 13 6.97266 13C7.21875 13 7.4375 12.8086 7.4375 12.5625V6.4375C7.4375 6.21875 7.21875 6 6.97266 6C6.75391 6 6.53516 6.21875 6.53516 6.4375ZM9.1875 6.4375V12.5625C9.1875 12.8086 9.37891 13 9.625 13C9.84375 13 10.0625 12.8086 10.0625 12.5625V6.4375C10.0625 6.21875 9.84375 6 9.625 6C9.37891 6 9.1875 6.21875 9.1875 6.4375Z"/>
			</svg>
		)
	),
	TrashSettings: (
		_icon(
			<svg width="12.2" height="14" viewBox="0 0 12.2 14" fill="none" xmlns="http://www.w3.org/2000/svg">
				<path
					  d="M3.7.5c.1-.3.4-.5.8-.5h3.3c.3,0,.6.2.8.5l.2.4h2.6c.5,0,.9.4.9.9s-.4.9-.9.9H.9c-.5,0-.9-.4-.9-.9s.4-.9.9-.9h2.6l.2-.4ZM.8,3.5h10.5v8.8c0,1-.8,1.8-1.8,1.8H2.6c-1,0-1.8-.8-1.8-1.8V3.5ZM3,5.7v6.1c0,.2.2.4.4.4s.4-.2.4-.4v-6.1c0-.2-.2-.4-.4-.4s-.4.2-.4.4ZM5.7,5.7v6.1c0,.2.2.4.4.4s.5-.2.5-.4v-6.1c0-.2-.2-.4-.5-.4s-.4.2-.4.4ZM8.3,5.7v6.1c0,.2.2.4.4.4s.4-.2.4-.4v-6.1c0-.2-.2-.4-.4-.4s-.4.2-.4.4Z"/>
			</svg>
		)
	),
	GooglePay: (
		_icon(
			<svg viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
				<path
					d="M16.125 8.1875C16.125 12.625 13.0938 15.75 8.625 15.75C4.3125 15.75 0.875 12.3125 0.875 8C0.875 3.71875 4.3125 0.25 8.625 0.25C10.6875 0.25 12.4688 1.03125 13.8125 2.28125L11.6875 4.3125C8.9375 1.65625 3.8125 3.65625 3.8125 8C3.8125 10.7188 5.96875 12.9062 8.625 12.9062C11.6875 12.9062 12.8438 10.7188 13 9.5625H8.625V6.90625H16C16.0625 7.3125 16.125 7.6875 16.125 8.1875Z"/>
			</svg>
		)
	),
	Document: (
		_icon(
			<svg viewBox="0 0 13 16" fill="none" xmlns="http://www.w3.org/2000/svg">
				<path
					d="M8.32031 0L12.3203 4H8.32031V0ZM7.32031 4C7.32031 4.5625 7.75781 5 8.32031 5H12.3203V14.5C12.3203 15.3438 11.6328 16 10.8203 16H1.82031C0.976562 16 0.320312 15.3438 0.320312 14.5V1.5C0.320312 0.6875 0.976562 0 1.82031 0H7.32031V4ZM8.82031 13C9.07031 13 9.32031 12.7812 9.32031 12.5C9.32031 12.25 9.07031 12 8.82031 12H3.82031C3.53906 12 3.32031 12.25 3.32031 12.5C3.32031 12.7812 3.53906 13 3.82031 13H8.82031ZM8.82031 11C9.07031 11 9.32031 10.7812 9.32031 10.5C9.32031 10.25 9.07031 10 8.82031 10H3.82031C3.53906 10 3.32031 10.25 3.32031 10.5C3.32031 10.7812 3.53906 11 3.82031 11H8.82031ZM9.32031 8.5C9.32031 8.25 9.07031 8 8.82031 8H3.82031C3.53906 8 3.32031 8.25 3.32031 8.5C3.32031 8.78125 3.53906 9 3.82031 9H8.82031C9.07031 9 9.32031 8.78125 9.32031 8.5Z"/>
            </svg>
        )
    ),
    CarAlt: (
		_icon(
            <svg viewBox="0 0 16 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M15.8203 7V8.125C15.8203 8.54688 15.4688 8.875 15.0703 8.875H14.2734C14.1094 9.95312 13.1719 10.75 12.0703 10.75C10.9453 10.75 10.0078 9.95312 9.84375 8.875H6.77344C6.60938 9.95312 5.67188 10.75 4.57031 10.75C3.44531 10.75 2.50781 9.95312 2.34375 8.875H1.57031C1.14844 8.875 0.820312 8.54688 0.820312 8.125V5.5C0.820312 4.86719 1.19531 4.32812 1.75781 4.11719L2.74219 1.67969C3.07031 0.8125 3.91406 0.25 4.82812 0.25H9.09375C9.77344 0.25 10.4062 0.578125 10.8516 1.11719L13.1953 4.02344C14.6719 4.21094 15.8203 5.47656 15.8203 7ZM4.82812 1.75C4.52344 1.75 4.24219 1.9375 4.125 2.24219L3.42188 4H6.07031V1.75H4.82812ZM7.19531 4H11.25L9.67969 2.03125C9.53906 1.86719 9.30469 1.75 9.09375 1.75H7.19531V4ZM13.125 8.875C13.1719 8.75781 13.1953 8.64062 13.1953 8.5C13.1953 7.89062 12.6797 7.375 12.0703 7.375C11.4375 7.375 10.9453 7.89062 10.9453 8.5C10.9453 8.64062 10.9453 8.75781 10.9922 8.875C11.1562 9.32031 11.5781 9.625 12.0703 9.625C12.5391 9.625 12.9609 9.32031 13.125 8.875ZM5.625 8.875C5.67188 8.75781 5.69531 8.64062 5.69531 8.5C5.69531 7.89062 5.17969 7.375 4.57031 7.375C3.9375 7.375 3.44531 7.89062 3.44531 8.5C3.44531 8.64062 3.44531 8.75781 3.49219 8.875C3.65625 9.32031 4.07812 9.625 4.57031 9.625C5.03906 9.625 5.46094 9.32031 5.625 8.875Z"/>
            </svg>
        )
    ),
    Pricing: (
		_icon(
            <svg viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M0.820312 12C0.820312 5.39062 6.16406 0 12.8203 0C19.4297 0 24.8203 5.39062 24.8203 12C24.8203 18.6562 19.4297 24 12.8203 24C6.16406 24 0.820312 18.6562 0.820312 12ZM13.7578 6.28125C13.7578 5.71875 13.3359 5.25 12.7734 5.25C12.2109 5.25 11.7422 5.71875 11.7422 6.28125V7.03125C11.4609 7.125 11.1797 7.17188 10.9453 7.3125C10.1016 7.6875 9.30469 8.34375 9.11719 9.375C8.88281 10.4531 9.35156 11.4375 10.2891 12C10.9453 12.4219 11.8359 12.7031 12.5391 12.8906C12.6328 12.9375 12.7266 12.9375 12.8672 12.9844C13.4297 13.125 13.9922 13.2656 14.4609 13.5938C15.0234 13.9688 14.7422 14.6719 14.1797 14.8594C13.8047 15.0469 13.1484 15.1406 12.3516 15C11.7891 14.9062 11.2734 14.7188 10.7109 14.5312C10.6172 14.5312 10.4766 14.4844 10.3828 14.4375C9.86719 14.25 9.25781 14.5312 9.11719 15.0938C8.92969 15.6094 9.21094 16.1719 9.72656 16.3594C10.3828 16.5938 11.0859 16.7812 11.7422 16.9219V17.7656C11.7422 18.3281 12.2109 18.75 12.7734 18.75C13.3359 18.75 13.7578 18.3281 13.7578 17.7656V17.0625C14.1797 17.0156 14.6016 16.9219 14.9766 16.7344C15.8203 16.4062 16.5703 15.7031 16.7578 14.6719C16.9453 13.5938 16.5234 12.5625 15.6328 11.9531C14.9297 11.4375 13.9922 11.2031 13.2422 10.9688C13.1484 10.9688 13.0547 10.9219 12.9141 10.875C12.3984 10.7344 11.8359 10.5938 11.3672 10.3125C10.8047 9.9375 11.2266 9.375 11.7422 9.14062C12.3047 8.90625 13.0078 8.85938 13.6641 8.95312C13.9922 9.04688 14.3203 9.14062 14.6484 9.23438C14.6953 9.23438 14.7891 9.28125 14.8359 9.28125C15.3984 9.42188 15.9609 9.14062 16.1016 8.57812C16.2422 8.0625 15.9141 7.5 15.3984 7.3125C15.3047 7.3125 15.2109 7.26562 15.1172 7.26562C14.6953 7.125 14.2266 6.98438 13.7578 6.9375V6.28125Z"/>
            </svg>
        )
    ),
    Car: (
        _icon(
            <svg viewBox="0 0 23 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M2.49609 7.21484L4 2.91797C4.60156 1.24219 6.14844 0.125 7.91016 0.125H15.6875C17.4492 0.125 18.9961 1.24219 19.5977 2.91797L21.1016 7.21484C22.0898 7.64453 22.8203 8.63281 22.8203 9.75V18C22.8203 18.7734 22.1758 19.375 21.4453 19.375H20.0703C19.2969 19.375 18.6953 18.7734 18.6953 18V15.9375H4.94531V18C4.94531 18.7734 4.30078 19.375 3.57031 19.375H2.19531C1.42188 19.375 0.820312 18.7734 0.820312 18V9.75C0.820312 8.63281 1.50781 7.64453 2.49609 7.21484ZM5.50391 7H18.0938L16.9766 3.82031C16.8047 3.26172 16.2891 2.875 15.6875 2.875H7.91016C7.30859 2.875 6.79297 3.26172 6.62109 3.82031L5.50391 7ZM4.94531 9.75C4.17188 9.75 3.57031 10.3945 3.57031 11.125C3.57031 11.8984 4.17188 12.5 4.94531 12.5C5.67578 12.5 6.32031 11.8984 6.32031 11.125C6.32031 10.3945 5.67578 9.75 4.94531 9.75ZM18.6953 12.5C19.4258 12.5 20.0703 11.8984 20.0703 11.125C20.0703 10.3945 19.4258 9.75 18.6953 9.75C17.9219 9.75 17.3203 10.3945 17.3203 11.125C17.3203 11.8984 17.9219 12.5 18.6953 12.5Z"/>
            </svg>
        )
    ),
    Alarm: (
        _icon(
            <svg viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M7.07031 1.51562L1.36719 6.24219C1.01562 5.65625 0.820312 4.99219 0.820312 4.25C0.820312 2.17969 2.46094 0.5 4.53125 0.5C5.50781 0.5 6.40625 0.890625 7.07031 1.51562ZM5.54688 18.7812L4.17969 20.1484C3.71094 20.6562 2.89062 20.6562 2.42188 20.1484C1.91406 19.6797 1.91406 18.8594 2.42188 18.3906L3.78906 17.0234C2.69531 15.5391 2.07031 13.7422 2.07031 11.75C2.07031 6.94531 5.97656 3 10.8203 3C15.625 3 19.5703 6.94531 19.5703 11.75C19.5703 13.7422 18.9062 15.5391 17.8125 17.0234L19.1797 18.3906C19.6875 18.8594 19.6875 19.6797 19.1797 20.1484C18.7109 20.6562 17.8906 20.6562 17.4219 20.1484L16.0547 18.7812C14.5703 19.875 12.7734 20.5 10.8203 20.5C8.82812 20.5 7.03125 19.875 5.54688 18.7812ZM11.7578 7.6875C11.7578 7.17969 11.3281 6.75 10.8203 6.75C10.2734 6.75 9.88281 7.17969 9.88281 7.6875V11.75C9.88281 12.0234 9.96094 12.2578 10.1562 12.4141L12.0312 14.2891C12.3828 14.6797 12.9688 14.6797 13.3203 14.2891C13.7109 13.9375 13.7109 13.3516 13.3203 12.9609L11.7578 11.3984V7.6875ZM20.2344 6.24219L14.5703 1.51562C15.1953 0.890625 16.0938 0.5 17.0703 0.5C19.1406 0.5 20.8203 2.17969 20.8203 4.25C20.8203 4.95312 20.5859 5.65625 20.2344 6.24219Z"/>
            </svg>
        )
    ),
    Clock: (
        _icon(
            <svg viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M12.8203 24C6.16406 24 0.820312 18.6562 0.820312 12C0.820312 5.39062 6.16406 0 12.8203 0C19.4297 0 24.8203 5.39062 24.8203 12C24.8203 18.6562 19.4297 24 12.8203 24ZM11.6953 12C11.6953 12.375 11.8828 12.75 12.1641 12.9375L16.6641 15.9375C17.1797 16.3125 17.8828 16.1719 18.2109 15.6562C18.5859 15.1406 18.4453 14.4375 17.9297 14.0625L13.9453 11.4375V5.625C13.9453 5.01562 13.4297 4.5 12.7734 4.5C12.1641 4.5 11.6484 5.01562 11.6484 5.625L11.6953 12Z"/>
            </svg>
        )
    ),
    ClockAlt: (
        _icon(
            <svg viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M16.3203 8C16.3203 12.4375 12.7266 16 8.32031 16C3.88281 16 0.320312 12.4375 0.320312 8C0.320312 5.78125 1.22656 3.75 2.69531 2.3125C3.10156 1.90625 3.72656 1.90625 4.13281 2.3125C4.50781 2.71875 4.50781 3.34375 4.10156 3.71875C3.00781 4.8125 2.32031 6.34375 2.32031 8C2.32031 11.3125 4.97656 14 8.32031 14C11.6328 14 14.3203 11.3125 14.3203 8C14.3203 5.03125 12.1328 2.5625 9.32031 2.09375V3C9.32031 3.5625 8.85156 4 8.32031 4C7.75781 4 7.32031 3.5625 7.32031 3V1C7.32031 0.46875 7.75781 0 8.32031 0C12.7266 0 16.3203 3.59375 16.3203 8ZM8.82031 7.46875C9.13281 7.78125 9.13281 8.25 8.82031 8.53125C8.53906 8.84375 8.07031 8.84375 7.78906 8.53125L5.28906 6.03125C4.97656 5.75 4.97656 5.28125 5.28906 4.96875C5.57031 4.6875 6.03906 4.6875 6.32031 4.96875L8.82031 7.46875Z"/>
            </svg>
        )
    ),
    ParkingSign: (
        _icon(
            <svg viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M11.9453 7.25C12.9766 7.25 13.8203 8.09375 13.8203 9.125C13.8203 10.2031 12.9766 11 11.9453 11H8.57031V7.25H11.9453ZM18.3203 0.5C19.9609 0.5 21.3203 1.85938 21.3203 3.5V18.5C21.3203 20.1875 19.9609 21.5 18.3203 21.5H3.32031C1.63281 21.5 0.320312 20.1875 0.320312 18.5V3.5C0.320312 1.85938 1.63281 0.5 3.32031 0.5H18.3203ZM11.9453 13.25C14.1953 13.25 16.0703 11.4219 16.0703 9.125C16.0703 6.875 14.1953 5 11.9453 5H7.44531C6.78906 5 6.32031 5.51562 6.32031 6.125V15.875C6.32031 16.5312 6.78906 17 7.44531 17C8.05469 17 8.57031 16.5312 8.57031 15.875V13.25H11.9453Z"/>
            </svg>
        )
    ),
    Refresh: (
        _icon(
            <svg viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M15 9C15.5312 9 16 9.46875 16 10C16 12.7812 13.75 15 11 15H6V16.25C6 16.5625 5.8125 16.8438 5.53125 16.9375C5.4375 17 5.34375 17 5.25 17C5.0625 17 4.875 16.9375 4.71875 16.8125L2.21875 14.5625C2.0625 14.4375 2 14.2188 2 14C2 13.8125 2.0625 13.5938 2.21875 13.4688L4.71875 11.2188C4.96875 11 5.28125 10.9688 5.53125 11.0938C5.8125 11.1875 6 11.4688 6 11.75V13H11C12.625 13 14 11.6562 14 10C14 9.46875 14.4375 9 15 9ZM5 5C3.34375 5 2 6.375 2 8C2 8.5625 1.53125 9 1 9C0.4375 9 0 8.5625 0 8C0 5.25 2.21875 3 5 3H10L9.96875 1.75C9.96875 1.46875 10.1562 1.1875 10.4375 1.0625C10.6875 0.9375 11.0312 0.96875 11.25 1.1875L13.75 3.4375C13.9062 3.5625 14 3.75 14 4C14 4.21875 13.9062 4.4375 13.7188 4.5625L11.2188 6.8125C11.0625 6.9375 10.875 7 10.7188 7C10.625 7 10.5312 7 10.4375 6.9375C10.1562 6.84375 10 6.5625 10 6.25L9.96875 5H5Z"/>
            </svg>
        )
	),
	SafeBadge: (
		_icon(
			<svg viewBox="0 0 21 23" fill="none" xmlns="http://www.w3.org/2000/svg">
				<path
					d="M19.5234 4.35938C20.2969 4.70312 20.8125 5.43359 20.8125 6.25C20.8125 17.25 12.6914 22.75 10.457 22.75C8.30859 22.75 0.1875 17.3359 0.1875 6.25C0.1875 5.43359 0.660156 4.70312 1.43359 4.35938L9.68359 0.921875C9.89844 0.835938 10.2422 0.792969 10.5 0.792969C10.7148 0.792969 11.0586 0.835938 11.2734 0.921875L19.5234 4.35938ZM14.625 9.34375C14.625 9.04297 14.3672 8.3125 13.5938 8.3125C13.293 8.3125 12.9922 8.39844 12.7773 8.65625L9.38281 12.6094L8.09375 11.3633C7.92188 11.1484 7.66406 11.0625 7.40625 11.0625C6.80469 11.0625 6.375 11.5352 6.375 12.0938C6.375 12.3945 6.46094 12.6523 6.67578 12.8242L8.73828 14.8867C8.91016 15.0586 9.16797 15.1875 9.46875 15.1875C9.55469 15.1875 9.94141 15.1875 10.2422 14.8438L14.3672 10.0312C14.5391 9.85938 14.625 9.60156 14.625 9.34375Z"/>
			</svg>
		)
	),
	RightChevron: (
		_icon(
			<svg viewBox="0 0 8 13" fill="none" xmlns="http://www.w3.org/2000/svg">
				<path
					d="M1.25 12.375C1.00391 12.375 0.785156 12.293 0.621094 12.1289C0.265625 11.8008 0.265625 11.2266 0.621094 10.8984L5.24219 6.25L0.621094 1.62891C0.265625 1.30078 0.265625 0.726562 0.621094 0.398438C0.949219 0.0429688 1.52344 0.0429688 1.85156 0.398438L7.10156 5.64844C7.45703 5.97656 7.45703 6.55078 7.10156 6.87891L1.85156 12.1289C1.6875 12.293 1.46875 12.375 1.25 12.375Z"/>
			</svg>
		)
	),
	LeftChevron: (
		_icon(
			<svg viewBox="0 0 7 11" fill="none" xmlns="http://www.w3.org/2000/svg">
				<path
					d="M5.5 10.75C5.28906 10.75 5.10156 10.6797 4.96094 10.5391L0.460938 6.03906C0.15625 5.75781 0.15625 5.26562 0.460938 4.98438L4.96094 0.484375C5.24219 0.179688 5.73438 0.179688 6.01562 0.484375C6.32031 0.765625 6.32031 1.25781 6.01562 1.53906L2.05469 5.5L6.01562 9.48438C6.32031 9.76562 6.32031 10.2578 6.01562 10.5391C5.875 10.6797 5.6875 10.75 5.5 10.75Z"/>
			</svg>
		)
	),
	Plus: (
		_icon(
			<svg viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
				<path
					d="M11.6875 5.75C11.6875 6.24219 11.2773 6.65234 10.8125 6.65234H6.875V10.5898C6.875 11.0547 6.46484 11.4375 6 11.4375C5.50781 11.4375 5.125 11.0547 5.125 10.5898V6.65234H1.1875C0.695312 6.65234 0.3125 6.24219 0.3125 5.75C0.3125 5.28516 0.695312 4.90234 1.1875 4.90234H5.125V0.964844C5.125 0.472656 5.50781 0.0625 6 0.0625C6.46484 0.0625 6.875 0.472656 6.875 0.964844V4.90234H10.8125C11.2773 4.875 11.6875 5.28516 11.6875 5.75Z"/>
			</svg>
		)
	),
	Check: (
		_icon(
			<svg viewBox="0 0 16 12" fill="none" xmlns="http://www.w3.org/2000/svg">
				<path
					d="M14.6875 1.3125C15.0938 1.6875 15.0938 2.34375 14.6875 2.71875L6.6875 10.7188C6.3125 11.125 5.65625 11.125 5.28125 10.7188L1.28125 6.71875C0.875 6.34375 0.875 5.6875 1.28125 5.3125C1.65625 4.90625 2.3125 4.90625 2.6875 5.3125L5.96875 8.59375L13.2812 1.3125C13.6562 0.90625 14.3125 0.90625 14.6875 1.3125Z"/>
			</svg>
		)
	),
	TrashXMark: (
		_icon(
			<svg viewBox="0 0 42 48" fill="none" xmlns="http://www.w3.org/2000/svg">
				<path
					d="M26.625 0C27.75 0 28.7812 0.65625 29.25 1.6875L30 3H39C40.5938 3 42 4.40625 42 6C42 7.6875 40.5938 9 39 9H3C1.3125 9 0 7.6875 0 6C0 4.40625 1.3125 3 3 3H12L12.6562 1.6875C13.125 0.65625 14.1562 0 15.2812 0H26.625ZM2.90625 12H39L36.9375 43.7812C36.8438 46.2188 34.875 48 32.4375 48H9.46875C7.03125 48 5.0625 46.2188 4.96875 43.7812L2.90625 12ZM13.4062 25.5938L17.8125 30L13.4062 34.4062C12.4688 35.3438 12.4688 36.75 13.4062 37.5938C14.25 38.5312 15.6562 38.5312 16.5 37.5938L20.9062 33.1875L25.4062 37.5938C26.25 38.5312 27.6562 38.5312 28.5 37.5938C29.4375 36.75 29.4375 35.3438 28.5 34.4062L24.0938 30L28.5 25.5938C29.4375 24.75 29.4375 23.3438 28.5 22.4062C27.6562 21.5625 26.25 21.5625 25.4062 22.4062L20.9062 26.9062L16.5 22.4062C15.6562 21.5625 14.25 21.5625 13.4062 22.4062C12.4688 23.3438 12.4688 24.75 13.4062 25.5938Z"/>
			</svg>
		)
	),
	Revert: (
		_icon(
			<svg viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
				<path
					d="M14.5 8C14.5 11.875 11.3438 15 7.5 15C5.96875 15 4.5 14.5312 3.28125 13.625C2.84375 13.2812 2.75 12.6562 3.09375 12.2188C3.4375 11.7812 4.0625 11.6875 4.5 12C5.375 12.6562 6.40625 13 7.5 13C10.25 13 12.5 10.7812 12.5 8C12.5 5.25 10.25 3.03125 7.5 3.03125C5.90625 3.03125 4.4375 3.78125 3.5 5H5.5C6.03125 5 6.5 5.46875 6.5 6C6.5 6.5625 6.03125 7 5.5 7H1C0.4375 7 0 6.5625 0 6V1.5C0 0.96875 0.4375 0.5 1 0.5C1.53125 0.5 2 0.96875 2 1.5V3.71875C3.3125 2.03125 5.3125 1.03125 7.5 1.03125C11.3438 1.03125 14.5 4.15625 14.5 8Z"
				/>
			</svg>
		)
	),
	ShoppingBasket: (
		_icon(
			<svg width="15" viewBox="0 0 15 13" fill="none" xmlns="http://www.w3.org/2000/svg">
				<path
					d="M3 0.5C3.25781 0.5 3.49219 0.710938 3.53906 0.96875L3.58594 1.25H13.4297C13.9219 1.25 14.2969 1.74219 14.1562 2.21094L12.8906 6.71094C12.7969 7.03906 12.5156 7.25 12.1641 7.25H4.73438L4.94531 8.375H12.1875C12.4922 8.375 12.75 8.63281 12.75 8.9375C12.75 9.26562 12.4922 9.5 12.1875 9.5H4.47656C4.21875 9.5 3.98438 9.3125 3.9375 9.05469L2.53125 1.625H1.3125C0.984375 1.625 0.75 1.39062 0.75 1.0625C0.75 0.757812 0.984375 0.5 1.3125 0.5H3ZM3.75 11.375C3.75 10.7656 4.24219 10.25 4.875 10.25C5.48438 10.25 6 10.7656 6 11.375C6 12.0078 5.48438 12.5 4.875 12.5C4.24219 12.5 3.75 12.0078 3.75 11.375ZM12.75 11.375C12.75 12.0078 12.2344 12.5 11.625 12.5C10.9922 12.5 10.5 12.0078 10.5 11.375C10.5 10.7656 10.9922 10.25 11.625 10.25C12.2344 10.25 12.75 10.7656 12.75 11.375Z"
				/>
			</svg>
		)
	),
	Star: (
		_icon(
			<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512">
				<path
					d="M316.9 18C311.6 7 300.4 0 288.1 0s-23.4 7-28.8 18L195 150.3 51.4 171.5c-12 1.8-22 10.2-25.7 21.7s-.7 24.2 7.9 32.7L137.8 329 113.2 474.7c-2 12 3 24.2 12.9 31.3s23 8 33.8 2.3l128.3-68.5 128.3 68.5c10.8 5.7 23.9 4.9 33.8-2.3s14.9-19.3 12.9-31.3L438.5 329 542.7 225.9c8.6-8.5 11.7-21.2 7.9-32.7s-13.7-19.9-25.7-21.7L381.2 150.3 316.9 18z"
				/>
			</svg>
		)
	),
	StarActiveStroke: (
		_icon(
			<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512">
				<path
					stroke="white"
					strokeWidth="30"
					d="M316.9 18C311.6 7 300.4 0 288.1 0s-23.4 7-28.8 18L195 150.3 51.4 171.5c-12 1.8-22 10.2-25.7 21.7s-.7 24.2 7.9 32.7L137.8 329 113.2 474.7c-2 12 3 24.2 12.9 31.3s23 8 33.8 2.3l128.3-68.5 128.3 68.5c10.8 5.7 23.9 4.9 33.8-2.3s14.9-19.3 12.9-31.3L438.5 329 542.7 225.9c8.6-8.5 11.7-21.2 7.9-32.7s-13.7-19.9-25.7-21.7L381.2 150.3 316.9 18z"
				/>
			</svg>
		)
	),
	MenuIcon: (
		_icon(
			<svg width="14" height="11" viewBox="0 0 14 11" fill="none" xmlns="http://www.w3.org/2000/svg">
				<path
					d="M0.875 1.375C0.875 0.910156 1.25781 0.5 1.75 0.5H12.25C12.7148 0.5 13.125 0.910156 13.125 1.375C13.125 1.86719 12.7148 2.25 12.25 2.25H1.75C1.25781 2.25 0.875 1.86719 0.875 1.375ZM0.875 5.75C0.875 5.28516 1.25781 4.875 1.75 4.875H12.25C12.7148 4.875 13.125 5.28516 13.125 5.75C13.125 6.24219 12.7148 6.625 12.25 6.625H1.75C1.25781 6.625 0.875 6.24219 0.875 5.75ZM13.125 10.125C13.125 10.6172 12.7148 11 12.25 11H1.75C1.25781 11 0.875 10.6172 0.875 10.125C0.875 9.66016 1.25781 9.25 1.75 9.25H12.25C12.7148 9.25 13.125 9.66016 13.125 10.125Z"
					/>
			</svg>
		)
	),
	XMark: (
		_icon(
			<svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
				<path
					d="M9.10156 1.87891L6.23047 4.75L9.10156 7.64844C9.45703 7.97656 9.45703 8.55078 9.10156 8.87891C8.77344 9.23438 8.19922 9.23438 7.87109 8.87891L5 6.00781L2.10156 8.87891C1.77344 9.23438 1.19922 9.23438 0.871094 8.87891C0.515625 8.55078 0.515625 7.97656 0.871094 7.64844L3.74219 4.75L0.871094 1.87891C0.515625 1.55078 0.515625 0.976562 0.871094 0.648438C1.19922 0.292969 1.77344 0.292969 2.10156 0.648438L5 3.51953L7.87109 0.648438C8.19922 0.292969 8.77344 0.292969 9.10156 0.648438C9.45703 0.976562 9.45703 1.55078 9.10156 1.87891Z"
					/>
			</svg>
		)
	),
	QRCode: (
		_icon(
			<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
				<path
					d="M144 80l0 96-96 0 0-96 96 0zM48 32C21.5 32 0 53.5 0 80l0 96c0 26.5 21.5 48 48 48l96 0c26.5 0 48-21.5 48-48l0-96c0-26.5-21.5-48-48-48L48 32zm96 304l0 96-96 0 0-96 96 0zM48 288c-26.5 0-48 21.5-48 48l0 96c0 26.5 21.5 48 48 48l96 0c26.5 0 48-21.5 48-48l0-96c0-26.5-21.5-48-48-48l-96 0zM304 80l96 0 0 96-96 0 0-96zm-48 0l0 96c0 26.5 21.5 48 48 48l96 0c26.5 0 48-21.5 48-48l0-96c0-26.5-21.5-48-48-48l-96 0c-26.5 0-48 21.5-48 48zM72 120l0 16c0 8.8 7.2 16 16 16l16 0c8.8 0 16-7.2 16-16l0-16c0-8.8-7.2-16-16-16l-16 0c-8.8 0-16 7.2-16 16zM88 360c-8.8 0-16 7.2-16 16l0 16c0 8.8 7.2 16 16 16l16 0c8.8 0 16-7.2 16-16l0-16c0-8.8-7.2-16-16-16l-16 0zM328 120l0 16c0 8.8 7.2 16 16 16l16 0c8.8 0 16-7.2 16-16l0-16c0-8.8-7.2-16-16-16l-16 0c-8.8 0-16 7.2-16 16zM256 304l0 160c0 8.8 7.2 16 16 16l32 0c8.8 0 16-7.2 16-16l0-64c0-8.8 7.2-16 16-16s16 7.2 16 16s7.2 16 16 16l64 0c8.8 0 16-7.2 16-16l0-96c0-8.8-7.2-16-16-16s-16 7.2-16 16s-7.2 16-16 16l-32 0c-8.8 0-16-7.2-16-16s-7.2-16-16-16l-64 0c-8.8 0-16 7.2-16 16zM368 448a16 16 0 1 0 0 32 16 16 0 1 0 0-32zm64 0a16 16 0 1 0 0 32 16 16 0 1 0 0-32z"/>
			</svg>
		)
	),
	ParkingMachine:
		_icon(
			<svg version="1.1" xmlns="http://www.w3.org/2000/svg">
				<path
					d="M0,0 L512,0 L528,4 L544,12 L553,19 L562,27 L571,39 L579,55 L583,71 L583,290 L555,423 L530,541 L501,678 L497,694 L490,711 L480,728 L471,739 L462,749 L452,758 L439,766 L428,771 L412,775 L101,775 L84,771 L67,763 L54,753 L39,738 L29,724 L19,705 L13,687 L-11,573 L-36,455 L-65,318 L-71,290 L-71,72 L-67,55 L-59,39 L-51,29 L-47,24 L-36,15 L-25,8 L-13,3 Z M236,63 L209,66 L186,71 L166,77 L151,83 L134,92 L119,103 L108,112 L95,124 L84,138 L79,148 L81,154 L91,169 L105,191 L121,216 L137,241 L142,248 L370,248 L377,238 L393,213 L404,196 L418,174 L429,157 L433,151 L432,145 L424,133 L415,122 L402,109 L384,96 L369,87 L352,79 L334,73 L313,68 L288,64 L276,63 Z M138,364 L132,369 L132,656 L136,660 L138,661 L210,661 L215,658 L217,654 L217,580 L316,580 L333,578 L353,573 L371,565 L383,557 L395,546 L406,531 L415,512 L420,493 L421,486 L421,460 L418,442 L413,426 L405,410 L396,398 L390,391 L378,382 L363,374 L345,368 L328,365 L317,364 Z M337,95 L338,95 L337,107 L323,203 L258,203 L260,198 L274,179 L290,158 L300,144 L316,123 L329,105 Z M217,412 L281,412 L295,415 L307,420 L316,426 L321,431 L327,439 L332,451 L334,463 L334,477 L332,489 L327,501 L319,512 L310,519 L296,526 L281,530 L274,531 L217,531 Z M174,816 L338,816 L338,980 L174,980 Z "
					transform="translate(71,0)"/>
			</svg>
		),
}

export default Icon;