import React, {useCallback, useEffect, useRef, useState} from "react";
import {useLocation, useNavigate, useParams, useSearchParams} from 'react-router-dom';
import "ol/ol.css";
import {Feature, Map} from "ol";
import {transform, transformExtent} from "ol/proj";
import * as mapSettings from "../assets/map.json";
import {containsExtent, getCenter} from "ol/extent";
import {Circle} from "ol/geom";
import {Coordinate} from "ol/coordinate";
import {
    addMapPin,
    addParkingMachine,
    checkMapWidthLimit,
    invisibleStyle,
    loadParkingZonesIntoMap, locationMarker,
    map,
    mapDebounce,
    myLocationMarker,
    myLocationMarkerStyleBackground,
    myLocationMarkerStyleCircle, parkingMachines, parkingMachineStyle,
    parkingZones,
    styleElement,
    visibleZonesInViewport,
} from "../components/Map";
import MapSearch, {FilterInitialValues} from "../components/MapSearch/MapSearch";
import MapZoom from "../components/MapZoom/MapZoom";
import MapMyLocation from "../components/MapMyLocation/MapMyLocation";
import MapResults from "../components/MapResults/MapResults";
import MapResultsSheet from "../components/MapResultsSheet/MapResultsSheet";
import DetailZoneItem from "../components/DetailZoneItem/DetailZoneItem";
import {fetchData} from "../services/api";
import {TFilter} from "../model/type/MapSearch/TFilter";
import {Mapbox} from "../components/Mapbox";
import Icon from "../components/Icon";
import styles from './Main.module.scss';
import DetailZoneActions from "../components/DetailZoneActions/DetalZoneActions";
import MapboxLogo from "../components/MapboxLogo/MapboxLogo";
import MapAttribution from "../components/MapAttribution/MapAttribution";
import {useTranslation} from "react-i18next";
import useFavoriteZone from "../model/useFavoriteZone";
import useLocalStorageState from "../hooks/useLocalStorageState";
import {usePreviousLocation} from "../hooks/PreviousLocationContext";
import {QrReader} from "react-qr-reader";


const {mapZoom, mapAnimationDuration, minZoomZonesVisible, detailZoneZoom, maxZoom} = mapSettings;

export let selectedFeature: Feature;
export let hoveredFeature: Feature;

export function useMap() {
    const mapRef = useRef<Map>();
    if (!mapRef.current) {
        mapRef.current = map;
    }
    return mapRef.current;
}

const Main = () => {
    const {t} = useTranslation();

    const mapRef = useRef<HTMLDivElement>(null);
    const [isMapResultsForceClose, setIsMapResultForceClose] = useState(false);
    const [filter, setFilter] = useState<TFilter>(FilterInitialValues);
    const filterRef = useRef(filter);
    const [filteredZones, setFilteredZones] = useState([]);
    const [hideResults, setHideResults] = useState(true);
    const [detailZone, setDetailZone] = useState(null);
    const [detailZonePricing, setDetailZonePricing] = useState(null);
    const navigate = useNavigate();
    const [snapPoints, setSnapPoints] = useState([-145, 100]);
    const mapboxClient = new Mapbox(process.env.REACT_APP_MAP_API_KEY);
    const [mapMove, setMapMove] = useState(true);
    const [pricingDetail, setPricingDetail] = useState(false);
    const detailZoneRef = useRef(null);
    const { zones, deleteRecord: deleteZoneRecord } = useFavoriteZone();

    const [selectedParkingMachine, setSelectedParkingMachine] = useState<null|string>(null);

    const mapViewCenter = map.getView().getCenter();
    const mapViewZoom = map.getView().getZoom();

    const [searchParams] = useSearchParams();

    // Načíst zóny které jsou označené jako oblíbené vždy
    useEffect(() => {
        zones.forEach((zone) => {
            let section = JSON.parse(zone.json);
            delete section.geometry;

            if (section.featureType === null || section.featureType === "parkingZone") {
                loadParkingZonesIntoMap([section]);
            }

            if (section.featureType === "parkingMachine") {
                addParkingMachine(JSON.parse(section.position), 'rgba(102, 102, 102)', section);
            }
        });
    }, [zones.length]);


    useEffect(() => {
        if (detailZone) {
            fetchData("Tickets/calculateprice", "POST", "body", {
                licensePlate: "string",
                sectionCode: detailZone.properties.featureType === "parkingZone" ? detailZone.properties.sectionCode : null,
                parkMachineCode: detailZone.properties.featureType === "parkingMachine" ? detailZone.properties.parkMachineCode : null,
                tariff: detailZone.properties.tariffInfo.tariffID,
                requestedMinutes: 60
            }).then((data) => {
                setDetailZonePricing(Number(data.priceTotal));
            });
        }
    }, [detailZone]);

    useEffect(() => {
        if (detailZone && selectedFeature) {
            const extent = selectedFeature.getGeometry().getExtent();
            const boundaries = transformExtent(extent, 'EPSG:3857', 'EPSG:4326');
            fetchData("Parking/parkMachines/2/byBoundaries", "GET", "url", {
                minLatitude: boundaries[1],
                minLongitude: boundaries[0],
                maxLatitude: boundaries[3],
                maxLongitude: boundaries[2]
            }).then((results) => {
                results.forEach((machine) => {
                    if (machine.sectionID == detailZone.properties.sectionID) {
                        let color = selectedParkingMachine === machine.parkMachineID ? 'rgb(212, 9, 57)' : 'rgba(102, 102, 102)';
                        addParkingMachine(JSON.parse(machine.position), color, machine);
                    }
                });
            });
        }
    }, [detailZone, selectedFeature]);

    // useEffect(() => {
    //     if (!detailZone) {
    //         setSelectedParkingMachine(null);
    //     }
    // }, [detailZone]);


    useEffect(() => {
        setTimeout(() => {
            if (detailZone && detailZoneRef.current && selectedFeature && selectedFeature instanceof Feature) {

                const geometry = selectedFeature.getGeometry();
                const featureCenter = geometry.getExtent();
                if (isMobile) {
                    const bottomOffset = detailZoneRef.current.offsetHeight;
                    map.getView().fit(featureCenter, {
                        size: map.getSize(),
                        padding: [10, 10, bottomOffset + 25, 10],
                        maxZoom: 18,
                        duration: mapAnimationDuration
                    });
                } else {
                    const leftOffset = 364;
                    const rightOffset = 364;
                    map.getView().fit(featureCenter, {
                        size: map.getSize(),
                        padding: [50, rightOffset + 35, 50, leftOffset + 35],
                        maxZoom: 18,
                        duration: mapAnimationDuration
                    });
                }

            }
        }, 100);
    }, [detailZone, detailZoneRef, pricingDetail, detailZoneRef.current?.offsetHeight, window.innerHeight, window.innerWidth]);

    useEffect(() => {
        filterRef.current = filter;
    }, [filter]);

    useEffect(() => {
        setFilter(filter);
        filterZonesOnMap();
    }, [filter]);

    useEffect(() => {
        filterZonesOnMap()
    }, [mapViewZoom, mapViewCenter, map.getView().getResolution()]);

    const filterZonesOnMap = () => {
        //todo: kdyz je vybrana zona tak ji pripadne zavrit pokud se tyka ovlivnene zony

        parkingZones.getSource().getFeatures().forEach((feature) => {
            let properties = feature.getProperties();
            if (filterRef.current.zones[properties.sectionCategoryID] === true) {
                if (selectedFeature?.getProperties().sectionID !== feature.getProperties().sectionID) {
                    feature.setStyle(styleElement(properties.sectionCategoryColor, false));
                    feature.setProperties({...properties, visible: true})
                }
            } else {
                feature.setStyle(invisibleStyle);
                feature.setProperties({...properties, visible: false})
            }

            if (filterRef.current.parking.to) {
                if ((filterRef.current.parking.to / 60) <= feature.getProperties().tariffInfo.maxHour) {
                    feature.setStyle(styleElement(properties.sectionCategoryColor, false));
                    feature.setProperties({...properties, visible: true})
                } else {
                    feature.setStyle(invisibleStyle);
                    feature.setProperties({...properties, visible: false})
                }
            }
        });

        parkingMachines.getSource().getFeatures().forEach((feature) => {
            let properties = feature.getProperties();

            // Todo: nahradit za properties.sectionCategoryID, az bude v API
            let parkingZone = parkingZones.getSource().getFeatureById(properties.sectionID);
            let sectionCategoryID;
            if (parkingZone instanceof Feature) {
                sectionCategoryID = parkingZone?.getProperties().sectionCategoryID;
            }

            if (filterRef.current.zones[sectionCategoryID] === true) {
                if (selectedFeature?.getProperties().parkMachineID !== feature.getProperties().parkMachineID) {
                    feature.setStyle(parkingMachineStyle("#666666"));
                    feature.setProperties({...properties, visible: true})
                }
            } else {
                feature.setStyle(invisibleStyle);
                feature.setProperties({...properties, visible: false})
            }

            if (filterRef.current.parking.to) {
                if ((filterRef.current.parking.to / 60) <= feature.getProperties().tariffInfo.maxHour) {
                    feature.setStyle(parkingMachineStyle("#666666"));
                    feature.setProperties({...properties, visible: true})
                } else {
                    feature.setStyle(invisibleStyle);
                    feature.setProperties({...properties, visible: false})
                }
            }
        });

        setFilteredZones(visibleZonesInViewport());
    };

    const isMobile = window.innerWidth < 575.98;
    const isDesktop = window.innerWidth >= 575.98;

    const loadDetailZone = (section: { [p: string]: any }, hasAnimation: boolean = false) => {
        setMapMove(false);
        redirectToSection((section.featureType === "parkingZone" ? section.sectionCode : section.parkMachineCode), section.featureType);

        if (selectedFeature) {
            const featureType: string = selectedFeature.getProperties().featureType;
            if (featureType === 'parkingZone') {
                selectedFeature.setStyle(styleElement(selectedFeature.getProperties().sectionCategoryColor, false));
            } else if (featureType === 'parkingMachine') {
                selectedFeature.setStyle(parkingMachineStyle("#666666"));
            }
            selectedFeature = null;
        }

        let feature;

        if (section.featureType === "parkingZone") {
            feature = parkingZones.getSource().getFeatureById(section.sectionID);
        } else if (section.featureType === "parkingMachine") {
            feature = parkingMachines.getSource().getFeatureById(section.parkMachineID);
        }

        if (feature instanceof Feature) {
            selectedFeature = feature;

            console.log(feature.getProperties().featureType);

            if (feature.getProperties().featureType === 'parkingZone') {
                feature.setStyle(styleElement(section.sectionCategoryColor, true));
            }

            if (feature.getProperties().featureType === 'parkingMachine') {
                feature.setStyle(parkingMachineStyle("#d40939"));
            }
        }

        if (!hasAnimation) {
            if (feature === null) {
                setTimeout(() => {
                    feature = parkingZones.getSource().getFeatureById(section.sectionID);
                    if (feature instanceof Feature) {
                        selectedFeature = feature;
                        if (feature.getProperties().featureType === 'parkingZone') {
                            feature.setStyle(styleElement(section.sectionCategoryColor, true));
                        }

                        if (feature.getProperties().featureType === 'parkingMachine') {
                            feature.setStyle(parkingMachineStyle("#d40939"));
                        }
                    }
                }, 500);
            }
        }

        let cordsDetail: Coordinate;

        if (feature instanceof Feature) {
            let geometry = feature.getGeometry();
            cordsDetail = transform(getCenter(geometry.getExtent()), 'EPSG:3857', 'EPSG:4326');
        }

        setDetailZone({
            coordinates: cordsDetail,
            properties: section
        })

        setIsMapResultForceClose(true);
    }

    useEffect(() => {
        if (filterRef.current.query.value.startsWith('mapbox_')) {
            // @ts-ignore
            mapboxClient.getSuggestedFeature({mapbox_id: filterRef.current.query.value.replaceAll("mapbox_", "")})
                .then((feature) => {
                    const coords = transform(feature.features[0].geometry.coordinates, 'EPSG:4326', 'EPSG:3857');
                    map.getView().animate({
                        center: coords,
                        zoom: maxZoom,
                        duration: mapAnimationDuration
                    });
                    locationMarker.getSource().clear();
                    addMapPin(feature.features[0]);
                })
        } else if (filterRef.current.query.value.startsWith('api_')) {
            fetchData("Parking/sections/2", "GET", "url",
                {
                    sectionCode: filterRef.current.query.value.replaceAll("api_", "")
                }).then((results) => {
                loadParkingZonesIntoMap(results).then(r => {

                    if (!results[0]) {
                        navigate("/");
                        setDetailZone(null);
                        selectedFeature = null;
                        return;
                    }

                    let feature = parkingZones.getSource().getFeatureById(results[0].sectionID);

                    if (!feature) {
                        navigate("/");
                        setDetailZone(null);
                        selectedFeature = null;
                        return;
                    }

                    setTimeout(() => {
                        if (feature instanceof Feature) {
                            loadDetailZone(feature.getProperties(), false);
                            selectedFeature = feature;
                            redirectToSection(feature.getProperties().sectionCode);
                            let geometry = feature.getGeometry();
                            let coordinates: Coordinate = transform(getCenter(geometry.getExtent()), 'EPSG:3857', 'EPSG:4326');
                            setDetailZone({
                                coordinates: coordinates,
                                properties: feature.getProperties()
                            })
                        }
                    }, 500)
                });
            });
        } else if (filterRef.current.query.value.startsWith('pm_')) {
            fetchData("Parking/parkMachines/2", "GET", "url",
                {
                    parkMachineCode: filterRef.current.query.value.replaceAll("pm_", "")
                }).then((parkingMachinesList) => {

                    parkingMachinesList.forEach((machine) => {
                        addParkingMachine(JSON.parse(machine.position), '#666666', machine);
                    });

                    if (!parkingMachinesList[0]) {
                        navigate("/");
                        setDetailZone(null);
                        selectedFeature = null;
                        return;
                    }

                    setTimeout(() =>{
                        const feature = parkingMachines.getSource().getFeatureById(parkingMachinesList[0].parkMachineID);

                        if (!feature) {
                            navigate("/");
                            setDetailZone(null);
                            selectedFeature = null;
                            return;
                        }

                        console.log(feature);
                        if (feature instanceof Feature) {
                            const properties = feature.getProperties();
                            selectedFeature = feature;
                            feature.setStyle(parkingMachineStyle("#d40939"));
                            const coordinates = getCenter(feature.getGeometry().getExtent());
                            setDetailZone({
                                coordinates: coordinates,
                                properties: feature.getProperties()
                            })
                            redirectToSection(properties.parkMachineCode, "parkingMachine");

                        }
                    }, 500)
                });
        }
    }, [filterRef.current.query])

    const isZoneVisibleOnMap = (sectionCode: string) => filteredZones.filter((zone) => zone.sectionCode === sectionCode).length > 0

    const location = useLocation();

    const isPageReloaded = ((sessionStorage.getItem("canUseReloadNavigation") ?? "true") === "true" &&
        (performance.getEntriesByType('navigation')[0] as PerformanceNavigationTiming | undefined)?.type === "reload");

    const shouldAutoLocate = location.key === "default" || isPageReloaded;

    useEffect(() => {
        sessionStorage.setItem("canUseReloadNavigation", "false");
    }, [isPageReloaded]);

    const getAutoLocation = useCallback(() => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    const {latitude, longitude} = position.coords;
                    const view = map.getView();
                    const currentCenter = view.getCenter();
                    const [mappedLongitude, mappedLatitude] = transform(
                        [longitude, latitude],
                        'EPSG:4326',
                        'EPSG:3857'
                    );
                    const currentZoom = view.getZoom();

                    if (hasAutoLocation && shouldAutoLocate) {
                        view.animate(
                            {
                                center: currentCenter,
                                zoom: currentZoom,
                            },
                            {
                                center: [mappedLongitude, mappedLatitude],
                                zoom: mapZoom,
                                duration: mapAnimationDuration,
                            }
                        );
                    }

                    const featureCircle = new Feature(new Circle([mappedLongitude, mappedLatitude], 4));
                    featureCircle.setStyle(myLocationMarkerStyleCircle);

                    const featureBg = new Feature(new Circle([mappedLongitude, mappedLatitude], 8));
                    featureBg.setStyle(myLocationMarkerStyleBackground);

                    myLocationMarker.getSource().addFeatures([featureBg, featureCircle])
                },
                (error) => {
                    //console.error("Error getting location:", error);
                }
            );
        } else {
            console.error("Geolocation is not supported by this browser.");
        }
    }, [map]);

    const [mapViewCenterState, setMapViewCenterState] = useLocalStorageState('map_ViewCenter', mapViewCenter);
    const [mapViewZoomState, setMapViewZoomState] = useLocalStorageState('map_ViewZoom', mapViewZoom);
    const [hasAutoLocation, setHasAutoLocation] = useLocalStorageState('map_autoLocation', true);

    // Set map view center to local storage state
    useEffect(() => {
        if (hasAutoLocation) {
            setMapViewCenterState(mapViewCenter);
            setMapViewZoomState(mapViewZoom);
        }
    }, [mapViewCenter, setMapViewCenterState, setMapViewZoomState, mapViewZoom]);

    const redirectToSection = (code: string, type: "parkingZone"|"parkingMachine" = "parkingZone") => {
        if (type === "parkingZone") {
            navigate(`/pz/${code}`);
        } else if (type === "parkingMachine") {
            navigate(`/pm/${code}`);
        }
    };

    const loadMapDataByPosition = async () => {
        if (map.getView().getZoom() >= minZoomZonesVisible) {
            parkingZones.setVisible(true);
            parkingMachines.setVisible(true);
            setHideResults(false);
        } else {
            if (!selectedFeature) {
                parkingZones.setVisible(false);
                parkingMachines.setVisible(false);

                setHideResults(true);
                setIsMapResultForceClose(false);
                setDetailZone(null);
                if (selectedFeature) {
                    selectedFeature.setStyle(styleElement(selectedFeature.getProperties().sectionCategoryColor, false));
                    selectedFeature = null;
                }
            }
        }

        if (checkMapWidthLimit() === true) {
            const boundaries: Array<number> = transformExtent(map.getView().calculateExtent(map.getSize()), 'EPSG:3857', 'EPSG:4326');
            if (boundaries[0] && boundaries[1] && boundaries[2] && boundaries[3]) {
                const data = await fetchData("Parking/sections/2/byBoundaries", "GET", "url", {
                    minLatitude: boundaries[1],
                    minLongitude: boundaries[0],
                    maxLatitude: boundaries[3],
                    maxLongitude: boundaries[2]
                });

                loadParkingZonesIntoMap(data).then(() => {
                    filterZonesOnMap();
                });
                setFilteredZones(visibleZonesInViewport());

                const parkingMachinesList = await fetchData("Parking/parkMachines/2/byBoundaries", "GET", "url", {
                    minLatitude: boundaries[1],
                    minLongitude: boundaries[0],
                    maxLatitude: boundaries[3],
                    maxLongitude: boundaries[2]
                });
                parkingMachinesList.forEach((machine) => {
                    addParkingMachine(JSON.parse(machine.position), 'rgba(102, 102, 102)', machine);
                });

                setDetailZone((state) => {
                    if (state !== null && data.filter(section => section.sectionCode === state.properties.sectionCode).length === 0) {
                        //if()
                        navigate(`/`);
                        return null;
                    }

                    return state;
                });

                if (detailZone !== null && data.filter(section => section.sectionCode === detailZone.properties.sectionCode).length === 0) {
                    setDetailZone(null);
                }
            }
        }
    }

    useEffect(() => {
        loadMapDataByPosition();
    }, []);

    const previousLocation = usePreviousLocation();

    useEffect(() => {
        if (mapRef.current) {
            map.setTarget(mapRef.current);
            map.updateSize();
        }

        map.on("movestart", (evt) => {
            setMapMove(true);
        })

        map.on("moveend", mapDebounce(async (evt) => {
            loadMapDataByPosition();
        }, 15));

        map.on("moveend", (evt) => {
            if (selectedFeature) {
                let viewExtent = map.getView().calculateExtent(map.getSize());
                let featureExtent = selectedFeature.getGeometry().getExtent();
                let isVisible = containsExtent(viewExtent, featureExtent);

                if (!isVisible && !map.getView().getAnimating() && mapMove === false) {
                    navigate(`/`);
                    setIsMapResultForceClose(false);
                    setDetailZone(null);
                    if (selectedFeature) {
                        selectedFeature.setStyle(styleElement(selectedFeature.getProperties().sectionCategoryColor, false));
                        selectedFeature = null;
                    }
                }
            }
        });

        map.on('pointermove', (evt) => {
            const pixel = map.getEventPixel(evt.originalEvent);
            const hit = map.hasFeatureAtPixel(pixel);

            if (hit) {
                const feature = map.forEachFeatureAtPixel(pixel, (feature) => feature);

                if (feature !== selectedFeature && feature instanceof Feature) {

                    if (feature.getProperties().featureType === 'parkingZone') {
                        if (hoveredFeature && hoveredFeature !== selectedFeature) {
                            if (hoveredFeature.getProperties().featureType === 'parkingZone') {
                                hoveredFeature.setStyle(styleElement(hoveredFeature.getProperties().sectionCategoryColor, false, false));
                            } else if (hoveredFeature.getProperties().featureType === 'parkingMachine') {
                                hoveredFeature.setStyle(parkingMachineStyle("#666666"));
                            }
                        }

                        feature.setStyle(styleElement(feature.getProperties().sectionCategoryColor, false, true));
                    }

                    if (feature.getProperties().featureType === 'parkingMachine') {
                        if (hoveredFeature && hoveredFeature !== selectedFeature) {
                            if (hoveredFeature.getProperties().featureType === 'parkingMachine') {
                                hoveredFeature.setStyle(parkingMachineStyle("#666666"));
                            } else if (hoveredFeature.getProperties().featureType === 'parkingZone') {
                                hoveredFeature.setStyle(styleElement(hoveredFeature.getProperties().sectionCategoryColor, false, false));
                            }
                        }

                        feature.setStyle(parkingMachineStyle("#d40939"));
                    }

                    hoveredFeature = feature;
                    map.getViewport().style.cursor = 'pointer';
                } else if (hoveredFeature && hoveredFeature !== selectedFeature && hoveredFeature.getProperties().featureType === 'parkingZone') {
                    hoveredFeature.setStyle(styleElement(hoveredFeature.getProperties().sectionCategoryColor, false, false));
                    hoveredFeature = null;
                    map.getViewport().style.cursor = '';
                }
            } else if (hoveredFeature && hoveredFeature !== selectedFeature && (hoveredFeature.getProperties().featureType === 'parkingZone')) {
                hoveredFeature.setStyle(styleElement(hoveredFeature.getProperties().sectionCategoryColor, false, false));
                hoveredFeature = null;
                map.getViewport().style.cursor = '';
            }
        });

        map.on('singleclick', (evt) => {
            const feature = map.forEachFeatureAtPixel(evt.pixel, (feature) => feature);

            if (selectedFeature && selectedFeature.getProperties().featureType === "parkingZone") {
                selectedFeature.setStyle(styleElement(selectedFeature.getProperties().sectionCategoryColor, false, false));
            }

            if (selectedFeature && selectedFeature.getProperties().featureType === "parkingMachine") {
                selectedFeature.setStyle(parkingMachineStyle("#666666"));
            }

            if (feature instanceof Feature && feature.getProperties().featureType === 'parkingMachine') {
                selectedFeature = null;

                const properties = feature.getProperties();
                selectedFeature = feature;
                feature.setStyle(parkingMachineStyle("#d40939"));
                const coordinates = getCenter(feature.getGeometry().getExtent());
                setDetailZone({
                    coordinates: coordinates,
                    properties: feature.getProperties()
                })

                redirectToSection(properties.parkMachineCode, "parkingMachine");
            }

            if (feature instanceof Feature && feature.getProperties().featureType === 'parkingZone'){
                const properties = feature.getProperties();

                if (properties.sectionCode !== selectedFeature?.getProperties().sectionCode) {
                    setIsMapResultForceClose(true);
                    loadDetailZone(properties, true);
                    redirectToSection(properties.sectionCode);
                }

                selectedFeature = feature;

                map.getViewport().style.cursor = '';
            } else if (feature?.getProperties()?.featureType !== 'parkingMachine') {
                navigate(`/`);
                setIsMapResultForceClose(false);
                selectedFeature = null;
                setDetailZone(null);
                setPricingDetail(false);
            }

            if (feature?.getProperties()?.sectionID && feature?.getProperties().featureType === 'parkingZone') {
                const geometry = feature.getGeometry();
                const coordinates = transform(getCenter(geometry.getExtent()), 'EPSG:3857', 'EPSG:4326');
                setDetailZone({
                    coordinates: coordinates,
                    properties: feature.getProperties()
                });
                redirectToSection(feature.getProperties().sectionCode);
            } else if (feature?.getProperties()?.featureType !== 'parkingMachine') {
                setDetailZone(null);
            }

            if (feature instanceof Feature && feature?.getProperties()?.sectionID && feature?.getProperties().featureType === 'parkingZone'){
                selectedFeature = feature;
                selectedFeature.setStyle(styleElement(feature.getProperties().sectionCategoryColor, true, null));
            }
        });

        getAutoLocation();

        // Při nákupu zpět na pozici na mapě, kde byl před tím
        if (!hasAutoLocation) {
            if (previousLocation.startsWith("/payment/") || previousLocation.startsWith("/paid/")) {
                map.getView().setCenter(mapViewCenterState);
                map.getView().setZoom(mapViewZoomState);
                console.log(previousLocation);
                setHasAutoLocation(true);
            } else {
                setHasAutoLocation(true);
                getAutoLocation();
            }
        }
    }, [map, getAutoLocation]);

    useEffect(() => {
        if (selectedFeature && !location.pathname.startsWith("/pz/") && !location.pathname.startsWith("/pm/")) {
            if (selectedFeature.getProperties().featureType === 'parkingZone') {
                selectedFeature.setStyle(styleElement(selectedFeature.getProperties().sectionCategoryColor, false));
            } else if (selectedFeature.getProperties().featureType === 'parkingMachine') {
                selectedFeature.setStyle(parkingMachineStyle("#666666"));
            }
            setDetailZone(null);
            selectedFeature = null;
        }
    }, [location]);

    const [filterIsOpened, setFilterIsOpened] = useState(false);

    const [showQrCodeReader, setShowQrCodeReader] = useState(false);

    useEffect(() => {
        if (showQrCodeReader) {
            setDetailZone(null)
        }
    }, [showQrCodeReader, detailZone]);

    const handleScan = (data) => {
        if (data.startsWith("https://parking.praha.eu/PA/")) {
            const parkingMachineQR = data.replace("https://parking.praha.eu/PA/", "");

            setShowQrCodeReader(false);

            fetchData("Parking/parkMachines/2", "GET", "url",
                {
                    parkMachineCode: parkingMachineQR
                }).then((parkingMachinesList) => {
                setSelectedParkingMachine(parkingMachinesList[0].parkMachineID);
                fetchData("Parking/sections/2", "GET", "url",
                    {
                        sectionCode: parkingMachinesList[0].sectionCode
                    }).then((results) => {
                    loadParkingZonesIntoMap(results).then(r => {
                        let feature = parkingZones.getSource().getFeatureById(results[0].sectionID);
                        setTimeout(() => {
                            if (feature instanceof Feature) {

                                loadDetailZone(feature.getProperties(), false);
                                selectedFeature = feature;
                                redirectToSection(feature.getProperties().sectionCode);
                                let geometry = feature.getGeometry();
                                let coordinates: Coordinate = transform(getCenter(geometry.getExtent()), 'EPSG:3857', 'EPSG:4326');
                                setDetailZone({
                                    coordinates: coordinates,
                                    properties: feature.getProperties()
                                })
                            }
                        }, 500)
                    });
                });
            });
        }
    };

    return (
        <div className={styles.container}>
            {detailZone && (
                <div className={styles.parkingZoneDetail}
                     style={{"border": "1px solid #666666E5"}}
                    //style={{"border": "1px solid " + selectedFeature?.getProperties().sectionCategoryColor}}
                     id="parkingZoneDetail" ref={detailZoneRef}>
                    <div className={styles.closeOverlay} onClick={() => {
                        navigate(`/`);
                        setIsMapResultForceClose(false);
                        selectedFeature.setStyle(styleElement(selectedFeature.getProperties().sectionCategoryColor, false));
                        selectedFeature = null;
                        setDetailZone(null);
                        setPricingDetail(false);
                    }}>
                        <Icon.RemoveTiny size={(isMobile ? 18 : 12)} color={'white'}/>
                    </div>
                        <DetailZoneItem
                        type={"name"}
                        value={detailZone.properties.featureType === "parkingZone" ? detailZone.properties.sectionCode : detailZone.properties.parkMachineCode}
                        subValue={t("home.MapSearchComponent.zones." + detailZone.properties.sectionCategoryName)}
                        featureType={detailZone.properties.featureType}
                        //subValue={detailZone.properties.sectionCategoryName}
                    />
                    {detailZone.properties.streetName && (
                        <DetailZoneItem type={"street"} value={detailZone.properties.streetName}/>
                    )}
                    <DetailZoneItem type={"time"} value={detailZone.properties.tariffInfo?.maxHour}/>
                    <DetailZoneItem
                        type="pricing"
                        value={detailZonePricing}
                        subValue={detailZone.properties.tariffInfo}
                        id={detailZone.properties.sectionCode}
                        onTogglePricingDetail={(state) => setPricingDetail(state)}
                        pricingDetailState={pricingDetail}
                    />
                    <DetailZoneActions
                        sectionCode={detailZone.properties.featureType === "parkingZone" ? detailZone.properties.sectionCode : detailZone.properties.parkMachineCode}
                        featureType={detailZone.properties.featureType}
                        section={selectedFeature?.getProperties()}
                        sectionId={selectedFeature?.getProperties()?.sectionID}
                    />
                </div>
            )}

            {showQrCodeReader ? (
                <div className={styles.qrCodeOverlay} onClick={() => setShowQrCodeReader(false)}>
                    <div className={styles.qrCodeContainer}>
                        <div className={styles.qrCodecloseOverlay} onClick={() => setShowQrCodeReader(false)}>
                            <Icon.RemoveTiny size={isMobile ? 18 : 12} color="white" />
                        </div>

                        <p className={styles.qrCodeText}>{t("home.QRCode.description")}</p>
                        <QrReader
                            onResult={(result, error) => {
                                if (result) {
                                    handleScan(result.getText());
                                }
                            }}
                            className={styles.qrReader}
                            constraints={{ facingMode: "environment", width: 300, height: 300 }}
                        />
                    </div>
                </div>
            ) : null}

            <div className={styles.map}>
                <div className={styles.mapContainer}>
                    <div id="map" className={styles.map} ref={mapRef}></div>
                </div>
            </div>

            {(isDesktop || (isMobile && detailZone === null)) && (
                <div className={styles.sidePanel}>
                    <MapSearch
                        filter={filter}
                        isZoneVisibleOnMap={isZoneVisibleOnMap}
                        onChangeFilter={(values) => {
                            setFilter(values);
                            filterZonesOnMap();
                        }}
                        wrapperClassName={styles.mapSearch}
                        onOpenFilter={() => {
                            setSnapPoints([-500, 100]);
                            setFilterIsOpened(true)
                        }}
                        onCloseFilter={() => {
                            setSnapPoints([-195, 100]);
                            setFilterIsOpened(false)
                        }}
                        onQrCodeClick={() => setShowQrCodeReader(true)}
                    />
                    <MapMyLocation showOn={"mobile"}/>

                    <MapResults
                        wrapperClassName={`${styles.mapResults} ${styles.showOnDesktop} ${filterIsOpened ? styles.filterIsOpened : ''}`}
                        filteredResults={filteredZones}
                        hideResults={hideResults}
                        currentSectionCode=
                            {detailZone?.properties.featureType === "parkingZone" ? detailZone?.properties.sectionID ?? null : detailZone?.properties.parkMachineID ?? null}
                        onSelect={(section) => {
                            loadDetailZone(section, true);
                        }}
                    />
                </div>
            )}

            <div id="mapControls">
                {
                    searchParams.get('zoomValue') === "true" ? (
                        <p style={{"position": "absolute", right: 16, bottom: 120, zIndex: "999999", background: "white"}}>Zoom: {map.getView().getZoom()}</p>
                    ) : null
                }

                {!detailZone ? (
                    <div className={styles.zoomPanel}>
                        {isDesktop ? <MapboxLogo/> : null}
                        <div className={styles.zoomButtonContainer}>
                            <MapZoom action={"in"} step={1}/>
                            <MapZoom action={"out"} step={1}/>
                        </div>
                        {isDesktop ? (
                            <div>
                                <MapAttribution/>
                            </div>
                        ) : null}
                        {
                            isMobile ? <>
                                    <MapboxLogo/>
                                    <MapAttribution/>
                                </>
                                : null
                        }
                    </div>
                ) : null}

                {!detailZone ? (
                    <div className={styles.locationPanel}>
                        <MapMyLocation showOn={"desktop"}/>
                    </div>
                ) : null}
                {!detailZone ?
                    <MapResultsSheet
                        forceClose={isMapResultsForceClose}
                        filteredZones={filteredZones}
                        hideResults={hideResults}
                        snapPoint={snapPoints}
                        onSelect={(section) => {
                            loadDetailZone(section, true);
                        }}
                    />
                    : null}
            </div>
        </div>
    );
}

export default Main;
