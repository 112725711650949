import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import styles from './ExtendParking.module.scss';
import Icon from "../components/Icon";
import {Link, useNavigate, useParams} from "react-router-dom";
import FormSelect from "components/FormSelect";
import {PARKING_TIME_VALUES} from "../constants";
import {isSafari} from "../model/helpers/BrowserHelper";
import {orderTicket} from "../model/helpers/Payment";
import i18n from "i18next";
import {getTicket} from "../model/helpers/Session";
import dayjs from "dayjs";
import {fetchData} from "../services/api";
import {addMinutesAndFormat, calculateParkingTimes} from "../model/helpers/DateHelper";
import {Form, Formik} from "formik";
import SelectField from "../components/SelectField";

const ExtendParking = () => {
    const {t } = useTranslation();
    const navigate = useNavigate();

    const requestedMinutesOptions = PARKING_TIME_VALUES;

    const [shouldRender, setShouldRender] = useState(false);
    const [toDate, setToDate] = useState(addMinutesAndFormat(Number(requestedMinutesOptions[0].value)));
    const [requestedMinutes, setRequestedMinutes] = useState(null);
    const [paymentMethod, setPaymentMethod] = useState(null);
    const [ticketData, setTicketData] = useState(null);
    const [section, setSection] = useState(null);
    const [priceForParking, setPriceForParking] = useState(null);

    const [disablePaymentButtons, setDisablePaymentButtons] = useState(false);

    let { ticketGUID } = useParams();

    useEffect(() => {
        getTicket(ticketGUID, (data) => {
            data.parkingFrom = dayjs(data.parkingFrom).toDate()
            data.parkingTo = dayjs(data.parkingTo).toDate()

            setTicketData(data);

            fetchData("Parking/sections/1", "GET", "url", {
                "sectionCode": data.sectionCode
            }).then((sectionsData) => {
                if (!sectionsData[0]) {
                    navigate(`/`);
                }

                let section = sectionsData[0];
                setSection(sectionsData[0]);
                requestedMinutesOptions.forEach((el) => {
                    if((Number(el.value) + data.acceptedMinutes) > (section.tariffInfo.maxHour * 60)) {
                        el.isDisabled = true;
                    }
                })

                setRequestedMinutes(requestedMinutesOptions[0])
            });

            const { remaining } = calculateParkingTimes(data.parkingFrom, data.parkingTo);

            if (remaining <= 0) {
                navigate(`/parking-session/${ticketGUID}`);
            } else {
                setShouldRender(true);
            }
        })
    }, [ticketGUID, navigate]);

    useEffect(() => {
        if(section?.tariffInfo && requestedMinutes) {
            fetchData("Tickets/calculateprice", "POST", "body", {
                // TODO: Licence plate string
                "licensePlate": "string",
                "sectionCode": section?.sectionCode,
                "tariff": section?.tariffInfo?.tariffID,
                "requestedMinutes": Number(requestedMinutes.value)
            }).then((data) => {
                if (ticketData?.parkingTo && requestedMinutes) {
                    setToDate(addMinutesAndFormat(Number(requestedMinutes.value), ticketData?.parkingTo));
                }
                setPriceForParking(Number(data.priceTotal));
            });
        }
    }, [section, requestedMinutes]);

    useEffect(() => {
        requestedMinutesOptions.forEach((el, i) => {
            el.label = t(PARKING_TIME_VALUES[i].labelString);
        })
    }, [i18n.language]);

    const handleOrderTicket = (data) => {
        data.licencePlate = ticketData.licensePlate;

        // TODO: jestli bude, tak vzít z API ticketData
        let email = 'test@appsdevteam.com';
        //orderTicket(ticketData.licensePlate, email, requestedMinutes, paymentMethod, section);

    }

    if (!shouldRender) {
        return null;
    }

    return (
        <Formik initialValues={{requestedMinutes: requestedMinutesOptions[0].value}} onSubmit={handleOrderTicket}>
            <div className={styles.containerWrapper}>
                <div className={styles.container}>
                    <Link to={`/paid/${ticketGUID}`} className={styles.back}>
                        <Icon.CaretLeft color={'danger'} size={16}/>
                        &nbsp;
                        <span className={'d-md-inline-block d-none'}>{t("extendParking.back")}</span>
                        <span className={'d-inline-block d-md-none'}>{t("extendParking.backMobile")}</span>
                    </Link>

                    <h1 className={styles.header}>
                        <span className={'d-md-inline-block d-none'}>{t("extendParking.title")}</span>
                        <span className={'d-inline-block d-md-none'}>{t("extendParking.titleMobile")}</span>
                    </h1>
                    <Form>
                        <div className="row">
                            <div className="col-md-6">
                                <div className={styles.item}>
                                    <div className={styles.info}>
                                        <div className={styles.icon}>
                                            <Icon.ParkingSign size={21}/>
                                        </div>
                                        <div className={styles.textMuted}>
                                            {section?.sectionCategoryName}
                                        </div>
                                        <div className={`${styles.itemEnd} ${styles.title} align-items-end`}>
                                            <div className={styles.itemTitle}>
                                                {ticketData.sectionCode}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className={styles.item}>
                                    <div className={styles.info}>
                                        <div className={styles.icon}>
                                            <Icon.Car size={21}/>
                                        </div>
                                        <div className={styles.textMuted}>
                                            {t('extendParking.car')}
                                        </div>
                                        <div className={`${styles.itemEnd} ${styles.title} align-items-end`}>
                                            <div className={styles.itemTitle}>
                                                {ticketData?.licensePlate}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className={styles.item}>
                            <div className={styles.info}>
                                <div className={styles.icon}>
                                    <Icon.Clock size={21}/>
                                </div>
                                <div className={styles.title}>
                                    <div className={`${styles.itemTitle} text-nowrap`}>
                                        {t("extendParking.extendBy")}<br className={'d-md-none'} />
                                        <span className={`${styles.textMuted} ${styles.textNormal} d-md-none`}>{t("buy.toDate")} {toDate}</span>
                                    </div>
                                </div>
                                <div className={`${styles.textMuted} d-none d-md-block`}>
                                    {t("buy.toDate")} {toDate}
                                </div>
                                <div className={styles.notifyInput}>
                                    <SelectField
                                        name={"requestedMinutes"}
                                        onChange={setRequestedMinutes}
                                        defaultValue={requestedMinutes}
                                        options={requestedMinutesOptions}
                                        isSearchable={false}
                                        required={true}
                                        components={{
                                            IndicatorSeparator: () => null
                                        }}
                                    />
                                </div>
                            </div>
                        </div>

                        <div className={styles.item}>
                            <div className={styles.info}>
                                <div className={styles.icon}>
                                    <Icon.Pricing size={21}/>
                                </div>
                                <div className={styles.title}>
                                    <div className={styles.itemTitle}>
                                        {t("extendParking.price")}
                                    </div>
                                </div>
                                <div className={styles.text500}>
                                    {priceForParking ?? section?.tariffInfo?.minPrice} {t("global.currency.czk")}
                                </div>
                            </div>
                        </div>

                        <div className={styles.formActions}>
                            {isSafari ?
                                <button disabled={disablePaymentButtons} type="submit"
                                        onClick={(e) => {
                                            e.preventDefault();
                                            alert(t("buy.wip")); /*setPaymentMethod('3')*/
                                        }}
                                        className={`${styles.buyButton} ${styles.appleButton}`}>
                                    <Icon.ApplePay className={styles.appleIcon} size={16}/>&nbsp;Pay with Apple
                                </button>
                                :
                                <button disabled={disablePaymentButtons} onClick={(e) => {
                                    e.preventDefault();
                                    alert(t("buy.wip")); /* setPaymentMethod('2')*/
                                }}
                                        className={`${styles.buyButton} ${styles.googleButton}`}>
                                    <Icon.GooglePay className={styles.googleIcon} size={16}/>&nbsp;Google Pay
                                </button>
                            }
                            <button disabled={disablePaymentButtons} type="submit" onClick={() => setPaymentMethod('0')} className={styles.mainButton}>
                                <Icon.PaymentCard color={'active'} size={16}/>&nbsp;{t("buy.payByCard")}
                            </button>
                        </div>
                    </Form>
                </div>
            </div>
        </Formik>
    );
}

export default ExtendParking;